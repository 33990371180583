import React from "react";
import { HeaderImage } from "../Common/HeaderImage";
import { Breadcrumbs } from "../Common/Breadcrumbs";
import { InfoCard } from "./InfoCard";
import { Management } from "./Management";
import { useTranslation } from "react-i18next";

export const Index = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col justify-center items-center pb-10 relative">
      <HeaderImage src="/img/about-us.png" title={t("aboutUs.title")} />

      <div className="flex flex-col justify-center items-center">
        <Breadcrumbs title={t("aboutUs.title")} href="about-us" />
      </div>
      {/*Section 1 */}
      <div className="relative w-full flex justify-center h-full text-justify mt-16 px-9 lg:px-0">
        <div className="container max-w-3xl 2xl:max-w-4xl">
          <h4 className="text-dark-cerulean font-bold text-2xl">
            {t("aboutUs.sectionOne.title")}
          </h4>
          <div
            className="font-light mt-3 flex flex-col gap-y-4 text-justify"
            dangerouslySetInnerHTML={{
              __html: t("aboutUs.sectionOne.content"),
            }}
          ></div>
        </div>
      </div>

      <div className="relative w-full flex justify-center h-full text-justify mt-16 px-9 lg:px-0 bg-white-smoke py-16">
        <div className="container max-w-3xl 2xl:max-w-4xl">
          <h4 className="text-dark-cerulean font-bold text-2xl">
            {t("aboutUs.sectionTwo.title")}
          </h4>
          <div
            className="font-light mt-3 flex flex-col gap-y-4 text-justify"
            dangerouslySetInnerHTML={{
              __html: t("aboutUs.sectionTwo.content"),
            }}
          ></div>
        </div>
      </div>

      {/* Section 3 */}
      <div className="w-full pb-7 flex items-center flex-col pt-6">
        <div className="container max-w-5xl 2xl:max-w-6xl flex flex-col justify-center items-center gap-y-5">
          <h1 className="text-dark-cerulean text-2xl font-bold">
            {t("aboutUs.sectionThree.title")}
          </h1>
          <Management />
        </div>
      </div>
      {/*Section 2 */}
      <div className="w-full lg:bg-white-smoke mt-10 pb-7 flex items-center flex-col pt-11">
        <div className="container max-w-4xl 2xl:max-w-5xl flex flex-col justify-center items-center gap-y-5">
          {/* <img
            alt="imc-logo"
            src={process.env.PUBLIC_URL + "/img/imc-logo.png"}
            className="w-44 h-20"
          /> */}
          <div className="justify-around w-full flex lg:flex-row flex-col gap-y-9 lg:gap-y-0">
            <InfoCard
              title={t("aboutUs.sectionFour.titleOne")}
              bgColor="bg-white"
              titleColor="text-main-blue"
              data={t("aboutUs.sectionFour.contentOne")}
            />

            <div className="border h-110 border-main-blue hidden lg:block" />
            <InfoCard
              title={t("aboutUs.sectionFour.titleTwo")}
              titleColor="text-main-blue"
              bgColor="bg-white-smoke"
              data={t("aboutUs.sectionFour.contentTwo")}
            />
          </div>
        </div>
      </div>
      {/*Section 4 */}
      <div className="relative w-full flex justify-center h-full text-justify mt-16 px-9 lg:px-0 items-center">
        <div className="container max-w-3xl 2xl:max-w-4xl">
          <h4 className="text-main-blue font-bold text-2xl text-center">
            {t("aboutUs.sectionFive.title")}
          </h4>
          <div
            className="font-medium mt-6 flex flex-col gap-y-4 text-center items-center justify-center"
            dangerouslySetInnerHTML={{
              __html: t("aboutUs.sectionFive.content"),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
