import IconSVG from "icons/IconSVG";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
import { NavLink } from "react-router-dom";

export default function DropdownHeader() {
  const { t } = useTranslation();
  return (
    <div
      id="dropdown"
      className="group inline-block z-10 font-bold self-center"
    >
      <button
        aria-haspopup="true"
        aria-controls="menu"
        className="outline-none focus:outline-none px-3 py-1 rounded-sm bg-transparent flex items-center min-w-32 gap-x-3"
      >
        <span className="pr-1 text-current font-medium text-custom flex-1">
          {t("header.services")}
        </span>
        <span>
          <IconSVG
            replace={false}
            className='"fill-current h-4 w-4 transform group-hover:-rotate-180
         transition duration-150 ease-in-out"'
            src={process.env.PUBLIC_URL + "/icons/arrow-down-icon.svg"}
          />
        </span>
      </button>
      <ul
        id="menu"
        aria-hidden="true"
        className="bg-white border min-w-[200px] rounded-sm font-bold hidden group-hover:block opacity-0 group-hover:opacity-100 absolute origin-top w-[200px]"
      >
        <li
          id="sectors"
          className="cursor-pointer relative px-5 text-dark-cerulean hover:text-white py-2 hover:bg-dark-cerulean h-10 flex items-center"
        >
          <button
            aria-haspopup="true"
            aria-controls="menu-sector"
            className="w-full text-left flex items-center outline-none focus:outline-none"
          >
            <span className="pr-1 flex-1 text-custom">
              {t("header.sector")}
            </span>
            <span className="mr-auto">
              <IoIosArrowDown />
            </span>
          </button>
          <ul
            id="menu-sector"
            aria-hidden="true"
            className="bg-white border text-custom12 rounded-sm absolute divide-y-[1px] -top-[1px] right-0 transition duration-300 ease-in-out origin-top-left w-[200px]"
          >
            <NavLink
              to="/sector/saglik-sektoru"
              className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
            >
              {t("header.sectors.health")}
            </NavLink>
            <NavLink
              to="/sector/insaat-sektoru"
              className=" px-5 py-2 no-underline flex items-center  text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
            >
              {t("header.sectors.construction")}
            </NavLink>
            <NavLink
              to="/sector/tasimacilik-sektoru"
              className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white   hover:bg-dark-cerulean h-10 "
            >
              {t("header.sectors.transportation")}
            </NavLink>
            <NavLink
              to="/sector/uretim-sektoru"
              className=" px-5 py-2 no-underline flex items-center  text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
            >
              {t("header.sectors.manufactoring")}
            </NavLink>
            <NavLink
              to="/sector/otomotiv-sektoru"
              className=" px-5 py-2 no-underline flex items-center  text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
            >
              {t("header.sectors.automotive")}
            </NavLink>
            <NavLink
              to="/sector/muhendislik-sektoru"
              className=" px-5 py-2 no-underline flex items-center  text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10"
            >
              {t("header.sectors.engineering")}
            </NavLink>
            <NavLink
              to="/sector/egitim-sektoru"
              className=" px-5 py-2 no-underline flex items-center  text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
            >
              {t("header.sectors.education")}
            </NavLink>
            <NavLink
              to="/sector/turizm-sektoru"
              className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
            >
              {t("header.sectors.tourism")}
            </NavLink>
            <NavLink
              to="/sector/spor-sektoru"
              className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
            >
              {t("header.sectors.sports")}
            </NavLink>
            <NavLink
              to="/sector/tarim-ve-hayvan-sektoru"
              className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 leading-none"
            >
              {t("header.sectors.agricultureAndLivestockBreeding")}
            </NavLink>
          </ul>
        </li>
        <li
          id="services"
          className="rounded-sm cursor-pointer relative px-5 py-2 no-underline text-dark-cerulean hover:text-white hover:bg-dark-cerulean h-10 flex items-center"
        >
          <button
            aria-haspopup="true"
            aria-controls="menu-services"
            className="w-full text-left flex items-center outline-none focus:outline-none"
          >
            <span className="pr-1 flex-1 text-custom">
              {t("header.services")}
            </span>
            <span className="mr-auto">
              <IoIosArrowDown />
            </span>
          </button>
          <ul
            id="menu-services-item"
            aria-hidden="true"
            className="bg-white border rounded-sm divide-y-[1px] absolute -top-[0.5px] right-0 transition duration-300 ease-in-out origin-top-left w-[200px]"
          >
            <li
              id="corp-services"
              className="px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10"
            >
              <button
                aria-haspopup="true"
                aria-controls="corporate-services"
                className="w-full text-left flex items-center outline-none focus:outline-none"
              >
                <span className="pr-1 flex-1 text-custom12">
                  {t("header.subServices.institutional.name")}
                </span>
                <span className="mr-auto">
                  <IoIosArrowDown />
                </span>
              </button>
              <ul
                id="corporate-services-item"
                aria-hidden="true"
                className="bg-white border divide-y-[1px] text-custom12 rounded-sm absolute -top-[1px] right-0 transition duration-500 ease-in-out origin-top-left w-[220px] leading-none"
              >
                <NavLink
                  to="/services/yangin-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.institutional.fire")}
                </NavLink>
                <NavLink
                  to="/services/is-yeri-paket-sigortalari"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10"
                >
                  {t("header.subServices.institutional.businessPackage")}
                </NavLink>
                <NavLink
                  to="/services/insaat-tum-riskler-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.institutional.constructionAllRisk")}
                </NavLink>
                <NavLink
                  to="/services/nakliyat-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.institutional.transportation")}
                </NavLink>
                <NavLink
                  to="/services/tekne-ve-yat-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.institutional.boatYacht")}
                </NavLink>
                <NavLink
                  to="/services/isveren-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.institutional.employer")}
                </NavLink>
                <NavLink
                  to="/services/ferdi-kaza-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.institutional.personalAccident")}
                </NavLink>
                <NavLink
                  to="/services/tehlikeli-madde-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.institutional.dangerousGoods")}
                </NavLink>
                <NavLink
                  to="/services/kefalet-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.institutional.surety")}
                </NavLink>
                {/* <NavLink
                  to="/services/emniyeti-suistimal-sigortalari"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10"
                >
                  Emniyeti Suistimal Sigortaları
                </NavLink> */}
              </ul>
            </li>
            <li
              id="individual-services"
              className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
            >
              <button
                aria-haspopup="true"
                aria-controls="individual-services"
                className="w-full text-left flex items-center outline-none focus:outline-none"
              >
                <span className="pr-1 flex-1 text-custom12">
                  {t("header.subServices.individual.name")}
                </span>
                <span className="mr-auto">
                  <IoIosArrowDown />
                </span>
              </button>
              <ul
                id="individual-services-item"
                aria-hidden="true"
                className="bg-white border text-custom12 absolute rounded-sm divide-y-[1px] -top-[1px] right-0 transition duration-500 ease-in-out origin-top-left w-[200px] leading-none"
              >
                <NavLink
                  to="/services/ozel-saglik-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.individual.private")}
                </NavLink>
                <NavLink
                  to="/services/tamamlayici-saglik-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.individual.complementary")}
                </NavLink>
                <NavLink
                  to="/services/sagliginiz-bizde-tss"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.individual.tss")}
                </NavLink>
                <NavLink
                  to="/services/dogum-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.individual.maternity")}
                </NavLink>
                <NavLink
                  to="/services/zorunlu-trafik-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.individual.motorVehicle")}
                </NavLink>
                <NavLink
                  to="/services/kasko-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.individual.comprehensive")}
                </NavLink>
                <NavLink
                  to="/services/dask-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.individual.earthquake")}
                </NavLink>
                <NavLink
                  to="/services/konut-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.individual.home")}
                </NavLink>
                <NavLink
                  to="/services/seyahat-sigortasi"
                  className=" px-5 py-2 no-underline flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 "
                >
                  {t("header.subServices.individual.travel")}
                </NavLink>
              </ul>
            </li>
            <NavLink
              to="/services/saglik-ve-calisan-yan-haklari"
              className=" px-5 py-2 no-underline text-custom12 flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10 leading-1"
            >
              {t("header.subServices.healthWorker")}
            </NavLink>
            <NavLink
              to="/reinsurance"
              className=" px-5 py-2 no-underline text-custom12 flex items-center text-dark-cerulean hover:text-white  hover:bg-dark-cerulean h-10"
            >
              {t("header.subServices.reasurance")}
            </NavLink>
          </ul>
        </li>
      </ul>
    </div>
  );
}
