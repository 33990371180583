import { Alert, Snackbar } from "@mui/material";
import { useDispatch } from "react-redux";
import { toastrClose } from "slices/CommonSlice";

export const Toastr = (props) => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(toastrClose());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: props.vertical,
        horizontal: props?.horizontal,
      }}
      open={props.open}
      autoHideDuration={3000}
      onClose={onClose}
      message={props.open}
      key={props.vertical + props.horizontal}
      className="z-[1600]"
    >
      <Alert onClose={onClose} severity={props.severity}>
        {props.message}
      </Alert>
    </Snackbar>
  );
};
