import { FormModal } from "components/Common/FormModal";
import { ErrorMessage, Form, Formik } from "formik";
import { Requests } from "libs/Api";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setLoading, setToastr } from "slices/CommonSlice";
import { getEntityUrl } from "utils/CommonUtils";
import { Checkbox } from "./FormElements/Checkbox";
import Input from "./FormElements/Input";
import { InputBase } from "./FormElements/InputBase";
import { PatternFormatInput } from "./FormElements/PatternFormatInput";
import { TextArea } from "./FormElements/TextArea";
import { CareerFormSchema } from "./Validation/CareerFormSchema";
import { CAREER_TOKEN, CAREER_URL, MASTER_IDENTIFIER } from "libs/Constant";

export default function CareerForm() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let initialValues = {
    FirstAndLastName: "",
    EmailAddress: "",
    MobileNumber: "",
    Content: "",
    FileBase64: "",
    Approval0: false,
    Approval1: false,
    Approval2: false,
  };
  const [showModal, setshowModal] = useState({
    open: false,
    title: "",
  });
  const [initialState, setInitialState] = useState(initialValues);
  const [fileBase64, setFileBase64] = useState("");
  const [postFileName, setPostFileName] = useState("");
  const [fileType, setFileType] = useState();
  const inputRef = useRef(null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setshowModal({ open: false, title: "" });
  };

  const getOfferFormSubmit = async (values) => {
    try {
      dispatch(setLoading(true));
      let response = {};
      let url = CAREER_URL;
      let content = {
        ...values,
        MobileNumber: values.MobileNumber.replaceAll(" ", ""),
        FileBase64: fileBase64,
        DocumentType: fileType,
      };
      response = await Requests().CommonRequest.post({
        url: url,
        content: content,
        headers: {
          CompanyIdentifier: MASTER_IDENTIFIER,
          Authorization: CAREER_TOKEN,
        },
      });
      setInitialState(initialValues);
      document.getElementById("career-form").reset();
      resetInput();
      dispatch(setLoading(false));
      setshowModal({
        open: true,
        title: "Talebiniz Alınmıştır.",
      });
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setToastr({
          open: true,
          message: "Form gönderilirken bir hata oluştu.",
          severity: "error",
        })
      );
    }
  };

  const resetInput = () => {
    inputRef.current.value = null;
    setPostFileName("");
    setFileBase64("");
  };

  const addDocument = async (event) => {
    try {
      const file = event.target?.files[0];
      if (file) {
        var fullPath = document.getElementById("FileBase64").value;
        if (fullPath) {
          const array = fullPath.split("\\");
          let filename = array[array.length - 1];
          let filetype = filename.substring(filename.lastIndexOf(".") + 1);
          setPostFileName(filename);
          setFileType(
            filetype === "pdf" ? "0" : filetype === "docx" ? "2" : "1"
          );
        }
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = async () => {
          const fileBase64 = btoa(reader.result);
          setFileBase64(fileBase64);
        };
      } else {
        dispatch(
          setToastr({
            open: true,
            message: "Lütfen geçerli bir dosya seçiniz.",
            severity: "info",
          })
        );
      }
    } catch (error) {
      dispatch(
        setToastr({
          open: true,
          severity: "error",
        })
      );
    }
  };
  return (
    <div className="flex items-center justify-center w-full h-full py-10 px-1 mt-10 relative bg-[#CCDAE7]">
      <div className="max-w-5xl 2xl:max-w-6xl w-full">
        <h1 className="text-4xl lg:text-[45px] text-dark-cerulean w-full text-center">
          {t("career.sectionForm.title")}
        </h1>
        <Formik
          enableReinitialize
          initialValues={initialState}
          validationSchema={CareerFormSchema}
          onSubmit={(values) => getOfferFormSubmit(values)}
        >
          {({ handleSubmit, handleChange, handleBlur, setFieldValue }) => (
            <Form
              id="career-form"
              onSubmit={handleSubmit}
              className="flex flex-col lg:grid lg:grid-cols-3 items-center gap-y-3 gap-x-4 mt-6 lg:mt-12 text-white"
            >
              <InputBase
                label={t("career.sectionForm.inputs.fullName.label")}
                component={Input}
                inputClassName="h-12 text-black text-[15px]"
                placeholder={t(
                  "career.sectionForm.inputs.fullName.placeHolder"
                )}
                type="text"
                name="FirstAndLastName"
              />

              <InputBase
                label={t("career.sectionForm.inputs.phoneNumber.label")}
                component={PatternFormatInput}
                inputClassName="h-12 text-black text-[15px]"
                placeholder={t(
                  "career.sectionForm.inputs.phoneNumber.placeHolder"
                )}
                type="text"
                name="MobileNumber"
                format="0 ### ### ## ##"
              />
              <InputBase
                label={t("career.sectionForm.inputs.eMail.label")}
                component={Input}
                inputClassName="h-12 text-black text-[15px]"
                placeholder={t("career.sectionForm.inputs.eMail.placeHolder")}
                type="text"
                name="EmailAddress"
              />
              <div className="col-span-2 w-full">
                <InputBase
                  label={t("career.sectionForm.inputs.content.label")}
                  component={TextArea}
                  placeholder={t(
                    "career.sectionForm.inputs.content.placeHolder"
                  )}
                  className="text-black text-[15px]"
                  type="text"
                  name="Content"
                />
              </div>
              <div className="flex flex-col w-full h-48">
                <label
                  htmlFor="addDocument"
                  className="pl-4 text-base font-light"
                >
                  {t("career.sectionForm.inputs.document.label")}
                </label>
                <div className="bg-white w-full h-full rounded-2xl relative outline-none">
                  {postFileName === "" ? (
                    <></>
                  ) : (
                    <button
                      type="button"
                      onClick={resetInput}
                      className="bg-main-blue rounded-full w-8 h-8 flex justify-center items-center absolute right-0 top-0"
                    >
                      <AiOutlineClose size={25}></AiOutlineClose>
                    </button>
                  )}

                  <label
                    for="FileBase64"
                    className="w-full h-full flex flex-col items-center justify-center text-xl text-[#A9A9A9] text-[15px] break-all text-center px-2"
                  >
                    {postFileName === "" ? (
                      <>
                        <AiOutlinePlus size={65}></AiOutlinePlus>{" "}
                        {t("career.sectionForm.inputs.document.placeHolder")}
                      </>
                    ) : (
                      <>{postFileName}</>
                    )}
                  </label>

                  <input
                    type="file"
                    onChange={addDocument}
                    name="FileBase64"
                    id="FileBase64"
                    accept=".doc,.docx,.pdf"
                    ref={inputRef}
                    className="rounded-2xl outline-none font-light text-xl px-5 hidden"
                  />
                </div>
                <div>
                  <ErrorMessage
                    className="text-red-500 text-sm pl-4"
                    component="span"
                    name="FileBase64"
                  />
                </div>
              </div>

              {/* <IconSVG
                    replace={false}
                    src={process.env.PUBLIC_URL + "/icons/plus-icon.svg"}
                    className="w-8 h-8 cursor-pointer"
                  /> */}
              <div className="col-span-2 flex flex-col gap-y-1">
                <Checkbox
                  setFieldValue={setFieldValue}
                  modal={{
                    title: t(
                      "career.sectionForm.approvalDetails.approvalOne.name"
                    ),
                    content: t(
                      "career.sectionForm.approvalDetails.approvalOne.content"
                    ),
                  }}
                  showModal={true}
                  name={`Approval0`}
                  btnLabel={t(
                    "career.sectionForm.approvalDetails.approvalOne.name"
                  )}
                  label={t("career.sectionForm.approvalDetails.acceptedTitle")}
                />
                <Checkbox
                  setFieldValue={setFieldValue}
                  modal={{
                    title: t(
                      "career.sectionForm.approvalDetails.approvalTwo.name"
                    ),
                    content: t(
                      "career.sectionForm.approvalDetails.approvalTwo.content"
                    ),
                  }}
                  showModal={true}
                  name={`Approval1`}
                  btnLabel={t(
                    "career.sectionForm.approvalDetails.approvalTwo.name"
                  )}
                  label={t("career.sectionForm.approvalDetails.acceptedTitle")}
                />
                <Checkbox
                  setFieldValue={setFieldValue}
                  modal={{
                    title: t(
                      "career.sectionForm.approvalDetails.approvalThree.name"
                    ),
                    content: t(
                      "career.sectionForm.approvalDetails.approvalThree.content"
                    ),
                  }}
                  showModal={true}
                  name={`Approval2`}
                  btnLabel={t(
                    "career.sectionForm.approvalDetails.approvalThree.name"
                  )}
                  label={t("career.sectionForm.approvalDetails.acceptedTitle")}
                />
                {/* {APPROVALS &&
                  APPROVALS.map((approval, index) => (
                    <Checkbox
                      setFieldValue={setFieldValue}
                      modal={{
                        title: approval?.ApprovalDetail?.Name,
                        content: approval?.ApprovalDetail?.Content,
                      }}
                      showModal={true}
                      name={`Approval${index}`}
                      btnLabel={approval?.ApprovalDetail?.Name}
                      label="okudum, anladım ve kabul ediyorum"
                    />
                  ))} */}
              </div>
              <div className="col-span-2 flex justify-end mt-16">
                <button
                  type="submit"
                  className="h-12 bg-[#4A98F7] px-7 text-white font-bold text-xl rounded-2xl lg:mr-20"
                >
                  {t("career.sectionForm.inputs.buttonTitle")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {showModal.open && (
        <FormModal
          open={showModal.open}
          title={showModal?.title}
          handleClose={handleClose}
          buttonColor="bg-[#4FA758]"
          // handleClose={() => {
          //   setshowModal({ open: false, title: "", details: "", imgUrl: "" });
          // }}
        />
      )}
    </div>
  );
}
