import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Notice from "./Notice";
import Footer from "./Footer";
import ScrollToTop from "libs/ScrollToTop";
import { useEffect, useState } from "react";
import { getEntityUrl, resErrorMessage } from "utils/CommonUtils";
import { Requests } from "libs/Api";
import CookiePolicy from "./CookiePolicy";
import { localStorageClearKeys } from "utils/LocalStorageUtils";

export default function MainLayout() {
  const location = useLocation();
  const [noticeData, setNoticesData] = useState([]);
  const [cookieSettingsOpen, setCookieSettingsOpen] = useState(false);

  const changeCookiePolicy = () => {
    localStorageClearKeys(["userCookiesSettings"]);
    setCookieSettingsOpen(true);
  };

  useEffect(() => {
    try {
      let url = getEntityUrl({
        api: {
          baseName: "BaseApiName",
          url: `Notices?Page=1&Size=10`,
        },
      });
      const response = Requests()
        .CommonRequest.get({
          url: url,
        })
        .then(({ data }) => {
          setNoticesData(data?.Value?.Data);
        })
        .catch((error) => {
          let errorMessage = resErrorMessage(error);
          console.log(errorMessage);
        });
    } catch (error) {
      let errorMessage = resErrorMessage(error);
      console.log(errorMessage);
    }
  }, []);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(true);
    const timeout = setTimeout(() => {
      setOpen(false);
    }, 15000);

    return () => clearTimeout(timeout);
  }, [location.pathname]);

  return (
    <div className="w-full h-full relative">
      {location.pathname == "/" && noticeData.length > 0 && (
        <Notice
          Open={open}
          Title={noticeData[0]?.Content?.Title}
          Img={noticeData[0]?.Content?.Media?.Link}
        />
      )}
      <Header />
      <Outlet />
      <Footer changeCookiePolicy={changeCookiePolicy} />
      <CookiePolicy
        cookieSettingsOpen={cookieSettingsOpen}
        setCookieSettingsOpen={setCookieSettingsOpen}
      />
      <ScrollToTop />
    </div>
  );
}
