import { useRoutes } from "react-router-dom";
import MainLayout from "./components/MainLayout/Index";
import { Home } from "./pages/Home";
import { Contact } from "./pages/Contact";
import { Reinsurance } from "./pages/Reinsurance";
import { AboutUs } from "./pages/AboutUs";
import { KVKK } from "./pages/KVKK";
import { InternetAccessInformation } from "./pages/InternetAccessInformation";
import { Blogs } from "./pages/Blogs";
import { Blog } from "./pages/Blog";
import { Sector } from "./pages/Sector";
import { Sector1 } from "./components/Sector/saglik-sektoru";
import { Sector2 } from "./components/Sector/insaat-sektoru";
import { Sector3 } from "./components/Sector/egitim-sektoru";
import { Sector4 } from "./components/Sector/muhendislik-sektoru";
import { Sector5 } from "./components/Sector/turizm-sektoru";
import { Sector6 } from "components/Sector/tarim-ve-hayvan-sektoru";
import { Sector7 } from "components/Sector/uretim-sektoru";
import { Sector8 } from "components/Sector/otomotiv-sektoru";
import { Sector9 } from "components/Sector/spor-sektoru";
import { Sector10 } from "components/Sector/tasimacilik-sektoru";
import { Service1 } from "components/Services/Institutional/is-yeri-paket-sigortalari";
import { Service2 } from "components/Services/Institutional/emniyeti-suistimal-sigortalari";
import { Service3 } from "components/Services/Institutional/kefalet-sigortasi";
import { Service4 } from "components/Services/Institutional/yangin-sigortasi";
import { Service5 } from "components/Services/Institutional/isveren-sigortasi";
import { Service6 } from "components/Services/Institutional/tehlikeli-madde-sigortasi";
import { Service7 } from "components/Services/Institutional/tekne-ve-yat-sigortasi";
import { Service8 } from "components/Services/Institutional/nakliyat-sigortasi";
import { Service9 } from "components/Services/Institutional/insaat-tum-riskler-sigortasi";
import { Service10 } from "components/Services/Institutional/ferdi-kaza-sigortasi";
import { Service11 } from "components/Services/Individual/dogum-sigortasi";
import { Service12 } from "components/Services/Individual/tamamlayici-saglik-sigortasi";
import { Service13 } from "components/Services/Individual/ozel-saglik-sigortasi";
import { Service14 } from "components/Services/Individual/dask-sigortasi";
import { Service15 } from "components/Services/Individual/sagliginiz-bizde-tss";
import { Service16 } from "components/Services/saglik-ve-calisan-yan-haklari";
import { Service17 } from "components/Services/Individual/konut-sigortasi";
import { Service18 } from "components/Services/Individual/zorunlu-trafik-sigortasi";
import { Service19 } from "components/Services/Individual/kasko-sigortasi";
import { Service20 } from "components/Services/Individual/seyahat-sigortasi";
import { DataProcess } from "pages/DataProcess";
import { GetOffer } from "pages/GetOffer";
import { ContactUs } from "pages/ContactUs";
import { Career } from "pages/Career";
import { SectorContactUs } from "pages/SectorContactUs";
import CookiePolicy from "pages/CookiePolicy";

function Router() {
  const routes = [
    {
      element: <MainLayout />,
      children: [
        { index: true, element: <Home /> },
        { path: "/contact", element: <Contact /> },
        { path: "/reinsurance", element: <Reinsurance /> },
        { path: "/about-us", element: <AboutUs /> },
        { path: "/get-offer", element: <GetOffer /> },
        { path: "/career", element: <Career /> },
        { path: "/contact-us", element: <ContactUs /> },
        { path: "/sector-contact-us", element: <SectorContactUs /> },
        { path: "/KVKK", element: <KVKK /> },
        { path: "/cookie-policy", element: <CookiePolicy /> },
        { path: "/data-process", element: <DataProcess /> },
        {
          path: "/internet-access-information",
          element: <InternetAccessInformation />,
        },
        { path: "/blogs", element: <Blogs /> },
        { path: "/blog/:id", element: <Blog /> },
        // { path: "/sector/:slug", element: <Sector /> },
        //#region Sektörler
        {
          path: "/sector/saglik-sektoru",
          element: <Sector1 />,
        },
        {
          path: "/sector/insaat-sektoru",
          element: <Sector2 />,
        },
        {
          path: "/sector/egitim-sektoru",
          element: <Sector3 />,
        },
        {
          path: "/sector/muhendislik-sektoru",
          element: <Sector4 />,
        },
        {
          path: "/sector/turizm-sektoru",
          element: <Sector5 />,
        },
        {
          path: "/sector/tarim-ve-hayvan-sektoru",
          element: <Sector6 />,
        },
        {
          path: "/sector/uretim-sektoru",
          element: <Sector7 />,
        },
        {
          path: "/sector/otomotiv-sektoru",
          element: <Sector8 />,
        },
        {
          path: "/sector/spor-sektoru",
          element: <Sector9 />,
        },
        {
          path: "/sector/tasimacilik-sektoru",
          element: <Sector10 />,
        },
        //#endregion

        //#region Hizmetler_Kurumsal
        {
          path: "/services/is-yeri-paket-sigortalari",
          element: <Service1 />,
        },
        {
          path: "/services/emniyeti-suistimal-sigortalari",
          element: <Service2 />,
        },
        {
          path: "/services/kefalet-sigortasi",
          element: <Service3 />,
        },
        {
          path: "/services/yangin-sigortasi",
          element: <Service4 />,
        },
        {
          path: "/services/isveren-sigortasi",
          element: <Service5 />,
        },
        {
          path: "/services/tehlikeli-madde-sigortasi",
          element: <Service6 />,
        },
        {
          path: "/services/tekne-ve-yat-sigortasi",
          element: <Service7 />,
        },
        {
          path: "/services/nakliyat-sigortasi",
          element: <Service8 />,
        },
        {
          path: "/services/insaat-tum-riskler-sigortasi",
          element: <Service9 />,
        },
        {
          path: "/services/ferdi-kaza-sigortasi",
          element: <Service10 />,
        },
        //#endregion
        //#region Hizmetler_Bireysel
        {
          path: "/services/dogum-sigortasi",
          element: <Service11 />,
        },
        {
          path: "/services/tamamlayici-saglik-sigortasi",
          element: <Service12 />,
        },
        {
          path: "/services/ozel-saglik-sigortasi",
          element: <Service13 />,
        },
        {
          path: "/services/dask-sigortasi",
          element: <Service14 />,
        },
        {
          path: "/services/sagliginiz-bizde-tss",
          element: <Service15 />,
        },
        {
          path: "/services/konut-sigortasi",
          element: <Service17 />,
        },
        {
          path: "/services/zorunlu-trafik-sigortasi",
          element: <Service18 />,
        },
        {
          path: "/services/kasko-sigortasi",
          element: <Service19 />,
        },
        {
          path: "/services/seyahat-sigortasi",
          element: <Service20 />,
        },

        //#endregion
        {
          path: "/services/saglik-ve-calisan-yan-haklari",
          element: <Service16 />,
        },
      ],
    },
  ];
  return useRoutes(routes);
}

export default Router;
