import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Slide,
  Fade,
} from "@mui/material";
import classNames from "classnames";
import { HiOutlineChevronDown } from "react-icons/hi";

export const AccordionItem = ({
  expanded,
  id,
  title,
  handleChange,
  children,
  detailsStyle,
}) => {
  return (
    <Accordion
      expanded={expanded === id}
      onChange={handleChange(id)}
      className="shadow-box-lg"
      sx={{ "&:before": { height: 0 } }}
    >
      <AccordionSummary
        expandIcon={
          <HiOutlineChevronDown
            size={40}
            className="text-black w-5 h-5 md:w-9 md:h-9"
          />
        }
        id={`${id}-header`}
        className="h-13 lg:h-16 px-3"
      >
        <h5
          className={classNames({
            "text-dark-cerulean font-bold transition-all duration-500": true,
            "text-xl": expanded === id,
          })}
        >
          {title}
        </h5>
      </AccordionSummary>
      <AccordionDetails
        className="w-full flex flex-wrap justify-start gap-x-10 gap-y-7 max-w-5xl px-2 lg:px-[45px]"
        style={detailsStyle}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
