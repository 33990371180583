import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumbs } from "../Common/Breadcrumbs";
import { HeaderImage } from "../Common/HeaderImage";
import { AccordionItem } from "./AccordionItem";

export const Sector5 = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col justify-center items-center relative">
      <HeaderImage
        src="/img/sector-8.jpg"
        title={t("sectors.tourism.title")}
        titleLeft={true}
        buttonTitle={t("sectors.tourism.buttonTitle")}
        buttonTo="/sector-contact-us"
        openButton={true}
      />

      <div className="flex flex-col justify-center items-center">
        <Breadcrumbs
          title2={t("sectors.tourism.title")}
          title={t("sectors.breadCrumb")}
          href="/"
        />
      </div>
      {/*section 1*/}
      <div className="relative w-full flex justify-center h-full text-left lg:text-justify mt-16 px-9 lg:px-0">
        <div className="container max-w-3xl 2xl:max-w-4xl flex flex-col gap-y-4">
          <p className="font-semibold text-san-marino">
            {t("sectors.tourism.content.title")}
          </p>
          <div
            className="flex flex-col gap-y-4 font-light"
            dangerouslySetInnerHTML={{
              __html: t("sectors.tourism.content.description.one"),
            }}
          ></div>
        </div>
      </div>

      {/*Section 2 */}
      <div className="w-full bg-white mt-28 pb-7 flex items-center flex-col pt-11">
        {/* <Faq /> */}
      </div>
    </div>
  );
};

export const Faq = () => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="max-w-5xl 2xl:max-w-6xl flex flex-col gap-y-5 w-full min-h-full pb-40 px-4 lg:px-0">
      <h1 className="text-dark-cerulean text-2xl lg:text-4xl font-bold">
        Sıkça Sorulan Sorular
      </h1>
      <div className="flex flex-col gap-y-5">
        <AccordionItem
          title="Seyahat sigortası almamın ne önemi var ?"
          id="panel-1"
          handleChange={handleChange}
          expanded={expanded}
        >
          <div className="flex flex-col font-light text-sm gap-y-3">
            <p>
              Seyahat sigortası almamanın olası risklerine karşı size
              sağlayabileceği avantajlardan bazıları:
            </p>
            <p>
              1. Finansal Koruma: Beklenmedik bir olay nedeniyle seyahatinizi
              iptal etmek zorunda kalırsanız veya seyahat sırasında tıbbi tedavi
              gerektiğinde, seyahat sigortası ödediğiniz masrafları karşılayarak
              finansal koruma sağlar. Seyahat iptal sigortası, uçak bileti,
              konaklama, tur ücretleri gibi masrafları geri almanıza yardımcı
              olabilir. Seyahat sağlık sigortası ise yurtdışında tıbbi tedavi
              gerektiğinde, hastane masrafları, ilaç giderleri, tıbbi tahliye
              gibi masrafları karşılayarak ciddi finansal yükümlülüklerden sizi
              koruyabilir.
            </p>
            <p>
              2. Bagaj Koruma: Seyahat sırasında bagajınızın kaybolması, hasar
              görmesi veya çalınması durumlarında seyahat sigortası ile
              uğradığınız zararları karşılayabilirsiniz. Bu, maddi değeri yüksek
              eşyalarınızın güvence altında olmasını sağlayarak finansal
              kayıplarınızı minimize edebilir.
            </p>
            <p>
              3. Acil Yardım ve Destek: Yurtdışında seyahat ederken acil bir
              durumla karşılaştığınızda seyahat sigortası size acil yardım ve
              destek sağlayabilir. Örneğin, acil tıbbi yardım, hukuki yardım,
              konsolosluk işlemleri gibi durumlarda seyahat sigortası size
              rehberlik ederek ve gerektiğinde profesyonel yardım sağlayarak
              zorlukların üstesinden gelmenize yardımcı olabilir.
            </p>
            <p>
              4. Seyahat Güvencesi: Seyahat sigortası, seyahat ederken
              karşılaşabileceğiniz beklenmedik durumlar nedeniyle oluşabilecek
              stresi azaltabilir ve size seyahat güvencesi sağlayabilir.
              Seyahatinizin iptal olması, tıbbi bir acil durum, bagaj kaybı gibi
              durumlarda, sigorta tarafından sunulan koruma ve destek size güven
              ve huzur verebilir.
            </p>
            <p>
              5. Özel Kapsamlar: Seyahat sigortaları, poliçe şartlarına bağlı
              olarak çeşitli ek kapsamlar içerebilir. Örneğin, spor
              etkinliklerine katılmak, evcil hayvanınızı seyahat ettirmek,
              hamilelik gibi özel durumlarda da ek kapsamlar ile genişletilmiş
              koruma sağlanabilir.
            </p>
            <p>
              Unutmayın ki seyahat sigortası poliçe şartlarına bağlı olarak
              değişebilir ve her sigorta şirketinin farklı ürünleri ve
              kapsamları bulunmaktadır. Bu ürün ve kapsamlar altında boğulmadan
              IMC Sigorta ve Reasürans Brokerliğinden destek almayı unutmayınız.
            </p>
          </div>
        </AccordionItem>
      </div>
    </div>
  );
};
