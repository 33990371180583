import { IV, PRIVATE_KEY } from "libs/Constant";
import { isObjectIsNotEmpty } from "./CommonUtils";
import { isEmpty } from "./StringUtils";
import CryptoJS from "crypto-js";

export const encrypt = (data, _key, _iv) => {
  let key = _key
    ? CryptoJS.enc.Utf8.parse(_key)
    : CryptoJS.enc.Utf8.parse(PRIVATE_KEY);
  let iv = _iv ? CryptoJS.enc.Utf8.parse(_iv) : CryptoJS.enc.Utf8.parse(IV);

  let encryptData = CryptoJS.AES.encrypt(data, key, {
    keySize: 32,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).ciphertext.toString();

  return encryptData.toString();
};

export const decrypt = (data, _key, _iv) => {
  let value = hexToBase64(data);

  try {
    let key = _key
      ? CryptoJS.enc.Utf8.parse(_key)
      : CryptoJS.enc.Utf8.parse(PRIVATE_KEY);
    let iv = _iv ? CryptoJS.enc.Utf8.parse(_iv) : CryptoJS.enc.Utf8.parse(IV);

    var bytes = CryptoJS.AES.decrypt(value, key, {
      keySize: 32,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  } catch (error) {}
};

const hexToBase64 = (str) => {
  var bString = "";
  for (var i = 0; i < str.length; i += 2) {
    bString += String.fromCharCode(parseInt(str.substr(i, 2), 16));
  }
  return btoa(bString);
};

export const combineAndEncryptParameters = (urlString, params) => {
  let result = urlString;
  let paramString;
  if (!isEmpty(params) && isObjectIsNotEmpty(params)) {
    paramString = Object.entries(params).reduce((res, [key, value]) => {
      let checkFilters = urlString.indexOf("?filters");
      if (key === "filters" && checkFilters === -1 && !isEmpty(value))
        res += `?${key}=${value}`;
      else if (!isEmpty(value)) {
        let urlStringQueryParam = urlString.indexOf("?");
        let paramStringQueyParam = res.indexOf("?");
        if (paramStringQueyParam == -1 && urlStringQueryParam == -1) {
          res += `?${key}=${value}`;
        } else {
          res += `&${key}=${value}`;
        }
      }
      return res;
    }, "");
    urlString += paramString ? paramString : "";
  }
  let querySplit = urlString?.split("?");

  if (querySplit && querySplit.length > 1) {
    let encryptQueryParam = `?Request=${encodeURIComponent(
      encrypt(querySplit[1])
    )}`;
    result = querySplit[0] + encryptQueryParam;
  }

  return result;
};
