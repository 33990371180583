import { FormModal } from "components/Common/FormModal";
import { Form, Formik } from "formik";
import { Requests } from "libs/Api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setLoading, setToastr } from "slices/CommonSlice";
import { getEntityUrl } from "utils/CommonUtils";
import { Checkbox } from "./FormElements/Checkbox";
import Input from "./FormElements/Input";
import { InputBase } from "./FormElements/InputBase";
import { PatternFormatInput } from "./FormElements/PatternFormatInput";
import { TextArea } from "./FormElements/TextArea";
import { CareerFormSchema } from "./Validation/CareerFormSchema";

export const Index = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  let initialValues = {
    FirstName: "",
    LastName: "",
    EmailAddress: "",
    MobileNumber: "",
    Content: "",
    Approval0: false,
    Approval1: false,
    Approval2: false,
  };
  const [initialState, setInitialState] = useState(initialValues);

  const [showModal, setshowModal] = useState({
    open: false,
    title: "",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setshowModal({ open: false, title: "" });
  };

  useEffect(() => {
    setInitialState({ ...initialState, Branch: location.state?.branchType });
  }, [location]);

  const getOfferFormSubmit = async (values) => {
    try {
      let url = getEntityUrl({
        api: {
          url: `Users/GetOfferWithMail`,
          baseName: "BaseApiName",
        },
      });
      dispatch(setLoading(true));
      let content = {
        ...values,
        MobileNumber: values.MobileNumber.replaceAll(" ", ""),
      };
      const response = await Requests().CommonRequest.post({
        url,
        content,
      });
      setInitialState(initialValues);
      setshowModal({
        open: true,
        title: t("contactUs.sectionForm.inputs.formSuccess"),
      });

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(
        setToastr({
          open: true,
          message: t("contactUs.sectionForm.inputs.formError"),
          severity: "error",
        })
      );
      dispatch(setLoading(false));
    }
  };
  return (
    <div className="flex min-h-[calc(100vh-20px)] bg-contact-us bg-center bg-cover bg-no-repeat">
      <div className="flex-1 flex justify-center py-10 px-10 relative bg-main-blue bg-opacity-50">
        <div className="max-w-xl 2xl:max-w-2xl w-full">
          <h1 className="font-abrilFatface text-4xl lg:text-[65px] text-white text-center lg:text-start">
            {t("contactUs.title")}
          </h1>
          <Formik
            enableReinitialize
            initialValues={initialState}
            validationSchema={CareerFormSchema}
            onSubmit={(values) => getOfferFormSubmit(values)}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              setFieldValue,
              values,
            }) => (
              <Form
                onSubmit={handleSubmit}
                className="flex flex-col lg:grid lg:grid-cols-2 items-center gap-y-3 gap-x-4 mt-6 lg:mt-12 text-white"
              >
                <InputBase
                  label={t("contactUs.sectionForm.inputs.name.label")}
                  component={Input}
                  inputClassName="h-12 text-black text-[15px]"
                  placeholder={t(
                    "contactUs.sectionForm.inputs.name.placeHolder"
                  )}
                  type="text"
                  name="FirstName"
                />
                <InputBase
                  label={t("contactUs.sectionForm.inputs.lastname.label")}
                  component={Input}
                  inputClassName="h-12 text-black text-[15px]"
                  placeholder={t(
                    "contactUs.sectionForm.inputs.lastname.placeHolder"
                  )}
                  type="text"
                  name="LastName"
                />
                <InputBase
                  label={t("contactUs.sectionForm.inputs.phoneNumber.label")}
                  component={PatternFormatInput}
                  inputClassName="h-12 text-black text-[15px]"
                  type="text"
                  name="MobileNumber"
                  format="0 ### ### ## ##"
                />
                <InputBase
                  label={t("contactUs.sectionForm.inputs.eMail.label")}
                  component={Input}
                  inputClassName="h-12 text-black text-[15px]"
                  placeholder={t(
                    "contactUs.sectionForm.inputs.eMail.placeHolder"
                  )}
                  type="text"
                  name="EmailAddress"
                />
                <div className="col-span-2 w-full">
                  <InputBase
                    label={t("contactUs.sectionForm.inputs.content.label")}
                    component={TextArea}
                    inputClassName="text-black text-[15px]"
                    placeholder={t(
                      "contactUs.sectionForm.inputs.content.placeHolder"
                    )}
                    type="text"
                    name="Content"
                  />
                </div>
                <div className="col-span-2 flex flex-col gap-y-1">
                  <Checkbox
                    setFieldValue={setFieldValue}
                    modal={{
                      title: t(
                        "contactUs.sectionForm.approvalDetails.approvalOne.name"
                      ),
                      content: t(
                        "contactUs.sectionForm.approvalDetails.approvalOne.content"
                      ),
                    }}
                    showModal={true}
                    name={`Approval0`}
                    btnLabel={t(
                      "contactUs.sectionForm.approvalDetails.approvalOne.name"
                    )}
                    label={t(
                      "contactUs.sectionForm.approvalDetails.acceptedTitle"
                    )}
                  />
                  <Checkbox
                    setFieldValue={setFieldValue}
                    modal={{
                      title: t(
                        "contactUs.sectionForm.approvalDetails.approvalTwo.name"
                      ),
                      content: t(
                        "contactUs.sectionForm.approvalDetails.approvalTwo.content"
                      ),
                    }}
                    showModal={true}
                    name={`Approval0`}
                    btnLabel={t(
                      "contactUs.sectionForm.approvalDetails.approvalTwo.name"
                    )}
                    label={t(
                      "contactUs.sectionForm.approvalDetails.acceptedTitle"
                    )}
                  />
                  <Checkbox
                    setFieldValue={setFieldValue}
                    modal={{
                      title: t(
                        "contactUs.sectionForm.approvalDetails.approvalThree.name"
                      ),
                      content: t(
                        "contactUs.sectionForm.approvalDetails.approvalThree.content"
                      ),
                    }}
                    showModal={true}
                    name={`Approval0`}
                    btnLabel={t(
                      "contactUs.sectionForm.approvalDetails.approvalThree.name"
                    )}
                    label={t(
                      "contactUs.sectionForm.approvalDetails.acceptedTitle"
                    )}
                  />
                  {/* {APPROVALS &&
                    APPROVALS.map((approval, index) => (
                      <Checkbox
                        setFieldValue={setFieldValue}
                        modal={{
                          title: approval?.ApprovalDetail?.Name,
                          content: approval?.ApprovalDetail?.Content,
                        }}
                        showModal={true}
                        name={`Approval${index}`}
                        btnLabel={approval?.ApprovalDetail?.Name}
                        label="okudum, anladım ve kabul ediyorum"
                      />
                    ))} */}
                </div>
                <div className="col-span-2 flex justify-start mt-16">
                  <button
                    type="submit"
                    className="h-12 bg-[#4A98F7] px-7 text-white font-bold text-xl rounded-2xl"
                  >
                    {t("contactUs.sectionForm.inputs.buttonTitle")}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="flex-1 relative hidden lg:flex bg-main-blue opacity-50 lg:justify-start lg:items-start"></div>
      {showModal.open && (
        <FormModal
          open={showModal.open}
          title={showModal?.title}
          handleClose={handleClose}
          buttonColor="bg-main-blue"
          // handleClose={() => {
          //   setshowModal({ open: false, title: "", details: "", imgUrl: "" });
          // }}
        />
      )}
    </div>
  );
};
