import { Breadcrumbs } from "components/Common/Breadcrumbs";
import { HeaderImage } from "components/Common/HeaderImage";
import { useTranslation } from "react-i18next";

export const Index = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col justify-center items-center pb-40 relative">
      <HeaderImage
        src="/img/footer-text-3.jpg"
        title={t("footer.text.webAccess.title")}
        titleLeft={true}
      />

      <div className="flex flex-col justify-center items-center">
        <Breadcrumbs
          title2={t("footer.text.webAccess.title")}
          title={t("footer.text.breadCrumb")}
          href="blogs"
        />
      </div>

      {/*Section 1 */}
      <div className="relative w-full flex justify-center h-full text-justify mt-16 px-9 lg:px-0">
        <div className="container max-w-3xl 2xl:max-w-4xl flex flex-col gap-y-16">
          <div
            dangerouslySetInnerHTML={{
              __html: t("footer.text.webAccess.content"),
            }}
            className="font-light mt-3 flex flex-col gap-y-4 text-justify"
          ></div>
        </div>
      </div>
    </div>
  );
};
