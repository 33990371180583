import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { TfiAngleDown } from "react-icons/tfi";
import { Link, NavLink, useLocation } from "react-router-dom";
import DropDown from "./DropDown";
import HamburgerMenu from "./HamburgerMenu";

import { useTranslation } from "react-i18next";
import { localStorageClearKeys } from "utils/LocalStorageUtils";

export default function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <div>
      <nav>
        <div className="max-w-8xl mx-auto px-4 sm:px-6 xl:px-40 2xl:px-60 h-16 border-b-[8px] border-main-blue">
          <div className="flex items-center justify-center gap-x-[28rem] max-xl:justify-between max-xl:gap-x-0 h-16 max-xl:px-0">
            <div className="flex items-center justify-center ">
              <HamburgerMenu />
              <div className="hidden md:flex items-center text-grey-1 lg:relative lg:-top-[1px]">
                <div className="ml-10 max-xl:ml-0 flex items-baseline space-x-9">
                  <NavLink
                    to="/"
                    className="font-medium self-center text-custom relative left-4 no-underline hover:text-current"
                  >
                    <div className="flex flex-col gap-y-1 items-center justify-center">
                      <span>{t("header.home")}</span>
                      <div
                        className={`w-[41px] bg-main-blue rounded-lg absolute top-[22px] h-[5px] ${
                          location.pathname == "/" ? "flex" : "hidden"
                        }`}
                      ></div>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/about-us"
                    className="font-medium text-custom relative group left-3 no-underline hover:text-current self-center"
                  >
                    <div className="flex flex-col gap-y-1 items-center justify-center">
                      <span>{t("header.aboutUs")}</span>
                      <div
                        className={`w-[41px] bg-main-blue rounded-lg absolute top-[22px] h-[5px] ${
                          location.pathname == "/about-us" ? "flex" : "hidden"
                        }`}
                      ></div>
                    </div>
                  </NavLink>
                  <DropDown />
                  <NavLink
                    to="/career"
                    className="font-medium text-custom relative group right-3 no-underline hover:text-current self-center"
                  >
                    <div className="flex flex-col gap-y-1 items-center justify-center">
                      <span>{t("header.career")}</span>
                      <div
                        className={`w-[41px] bg-main-blue rounded-lg absolute top-[22px] h-[5px] ${
                          location.pathname == "/career" ? "flex" : "hidden"
                        }`}
                      ></div>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/contact"
                    className="font-medium text-custom relative right-3 no-underline hover:text-current self-center"
                  >
                    <div className="flex flex-col gap-y-1 items-center justify-center">
                      <span>{t("header.contact")}</span>
                      <div
                        className={`w-[41px] bg-main-blue rounded-lg absolute top-[22px] h-[5px] ${
                          location.pathname == "/contact" ? "flex" : "hidden"
                        }`}
                      ></div>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center justify-center gap-x-4 lg:-left-10 lg:relative">
              <HeaderLangNavigation />
              <div className="flex flex-shrink-0 items-center justify-start relative -top-[3px] lg:relative lg:-top-1 w-[152px] h-[52px] max-xl:w-[140px] bg-transparent">
                <Link to="/" className="max-xl:w-32">
                  <img
                    src={process.env.PUBLIC_URL + "/img/imc_logo.png"}
                    className="w-[136px] h-[39px]"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

const HeaderLangNavigation = () => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(
    localStorage.getItem("language") || "tr"
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setSelected(localStorage.getItem("language"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const { i18n } = useTranslation();

  const buttonRef = useRef();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleChangeLanguage = async (lng) => {
    localStorageClearKeys(["userCookies"]);
    window.location.reload(false);
    await i18n.changeLanguage(lng);
    setSelected(lng);
  };

  return (
    <div className="hidden md:flex flex-col relative w-24 lg:relative lg:-top-1">
      <button
        type="button"
        ref={buttonRef}
        onClick={() => setOpen(!open)}
        className="rounded-3xl relative flex justify-between items-center gap-x-2 text-black h-10 md:w-25 lg:w-25 sm:w-25 w-25 px-2"
      >
        <img
          src={
            process.env.PUBLIC_URL + selected === "tr"
              ? "/img/tr-flag.svg"
              : "/img/eng-flag.svg"
          }
          className="w-6 h-6"
        />
        <h6 className="max-md:hidden w-7 font-light text-custom">
          {selected.toLocaleUpperCase()}
        </h6>
        <div className="w-4 h-4">
          <TfiAngleDown
            size={15}
            className={classNames({
              "transition-all duration-500": true,
              "rotate-180 ": open,
              "rotate-0": !open,
            })}
          />
        </div>
      </button>
      <div
        className={classNames({
          "absolute top-10 z-20 right-0 w-24 bg-white opacity-0 transition-all duration-300 flex items-center justify-start px-[10px]": true,
          "hidden opacity-0": !open,
          "opacity-100": open,
        })}
      >
        <button
          onClick={() => {
            setOpen(false);
            handleChangeLanguage(selected === "tr" ? "en" : "tr");
          }}
          className="flex h-10 font-light items-center justify-start gap-x-4 text-black w-full"
        >
          <img
            src={
              process.env.PUBLIC_URL + selected === "tr"
                ? "/img/eng-flag.svg"
                : "/img/tr-flag.svg"
            }
            className="w-6 h-4"
          />
          <span className="font-light text-custom relative -left-1">
            {selected === "tr" ? "EN" : "TR"}
          </span>
          <div className="w-4 h-4">
            <TfiAngleDown
              size={15}
              className={classNames({
                "transition-all duration-500": true,
                "rotate-180 ": open,
                "rotate-0": !open,
              })}
            />
          </div>
        </button>
      </div>
    </div>
  );
};
