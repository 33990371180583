import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const sampleData = [
  {
    title: "Sigortacılıkta Güvenilir Partner",
    link: "/blog/sigortacilikta-guvenilir-partner-1",
  },
  {
    title: "Nakliyat Sırasında Yaşanan Sıkıntılar",
    link: "/blog/nakliyat-sirasinda-yasanan-sikintilar-2",
  },
  {
    title: "Günümüzde Drone Kullanımının Artması",
    link: "/blog/gunumuzde-drone-kullaniminin-artmasi-3",
  },
];

export const LatestArticle = ({ data }) => {
  return (
    <ul className="text-dark-cerulean font-light text-base text-start lg:text-2xl">
      {data
        .sort((a, b) => a.OrderNumber - b.OrderNumber)
        .map((item, index) => (
          <Article
            key={index}
            title={item.Content?.Title}
            id={item.Content?.Id}
            link={item.Content?.Title}
            bgColor={index % 2 === 0}
          />
        ))}
    </ul>
  );
};

const Article = ({ id, title, link, bgColor }) => {
  const { t } = useTranslation();
  return (
    <li
      className={classNames({
        "h-24 px-6 flex items-center justify-between hover:bg-blog-gradient group": true,
        "bg-alice-blue": bgColor,
        "bg-white": !bgColor,
      })}
    >
      <span>{title}</span>
      <div className="bg-transparent hidden group-hover:block">
        <Link
          to={`/blog/${id}`}
          className="h-8 bg-dark-cerulean rounded-2xl text-white font-bold py-2 px-3 text-base"
        >
          {t("blogs.read")}
        </Link>
      </div>
    </li>
  );
};
