import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function ContactUs() {
  const { t } = useTranslation();
  return (
    <div className="w-full bg-contact-us bg-cover bg-center bg-no-repeat max-sm:h-96">
      <div className="w-full h-full flex flex-col items-center gap-y-10 max-md:gap-y-4 justify-center px-9 py-12 bg-main-blue bg-opacity-75">
        <div className="lg:text-4xl text-5xl font-medium text-white font-abrilFatface">
          {t("homePage.contactUs.title")}
        </div>
        <div className="flex flex-row items-center justify-center lg:mt-0 mt-10">
          <button
            type="button"
            className="border border-white text-white w-[200px] lg:w-[242px] h-[39px] backdrop-blur-xl text-[20px] font-light rounded-2xl max-md:text-sm"
          >
            <Link to="/contact-us" className="hover:no-underline">
              {t("homePage.contactUs.button")}
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
