import SVG from "react-inlinesvg";

export default function IconSVG({
  src,
  title,
  width = 26,
  height = 26,
  replace = true,
  ...props
}) {
  return (
    <SVG
      src={src}
      title={title}
      width={width}
      height={height}
      cacheRequests={true}
      preProcessor={(code) => {
        if (replace) return code.replace(/fill=".*?"/g, 'fill="currentColor"');
        else return code;
      }}
      {...props}
    />
  );
}
