import classNames from "classnames";

export const Select = ({ options, className, field, ...props }) => {
  return (
    <select
      className={classNames({
        "bg-white border border-light-grey w-full h-12 rounded-2xl outline-none font-light px-5": true,
        [className]: className,
      })}
      {...field}
      {...props}
    >
      <option value="">Lütfen Seçiniz</option>
      {options &&
        options.map((option) => (
          <option key={option.Id} value={option.Id}>
            {option.Name}
          </option>
        ))}
    </select>
  );
};

export default Select;
