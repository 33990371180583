import React from "react";
import Router from "./Router";
import { useSelector } from "react-redux";
import "./index.css";
import { Loading } from "components/Loading";
import { Toastr } from "components/Toastr";
import { AwardModal } from './components/Award';

function App() {

  const { loading, toastr, avardModalIsShowed } = useSelector((state) => state?.common);
  return (
    <>
      <Router />
      {avardModalIsShowed && <AwardModal/>}
      {/* {loading && <Loading />} */}
      {toastr.open && <Toastr {...toastr} />}
    </>
  );
}

export default App;
