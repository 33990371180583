import React from "react";
import { IMG_URL, IMG_URL_PORTAL } from "libs/Constant";

export default function Notice({ Title, Open, Img }) {
  return (
    <div
      className={`bg-charcoal w-full ${
        Open == true ? "flex" : "hidden"
      } h-16 px-5 md:h-14`}
    >
      <div className="flex items-center justify-center w-full h-full gap-x-5 text-white text-center text-lg max-md:text-xs">
        <div>
          <img className="w-8 h-8" alt="" src={`${IMG_URL_PORTAL + Img}`} />
        </div>
        <p className="md:text-lg font-light">{Title}</p>
        <div>
          <img className="w-8 h-8" alt="" src={`${IMG_URL_PORTAL + Img}`} />
        </div>
      </div>
    </div>
  );
}
