import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { SocialMedia } from "./SocialMedia";
import { IMG_URL, IMG_URL_PORTAL } from "libs/Constant";
import { useTranslation } from "react-i18next";

const sampleData = {
  sectionOne: [
    {
      img: "/img/blog-img-1.png",
      title: "Sigortacılıkta Güvenilir Partner",
      link: "blog/sigortacilikta-guvenilir-partner-1",
      description:
        "Gelişen teknoloji ve değişen müşteri beklentileriyle birlikte sigorta sektöründe önemli bir dönüşüm yaşamaktadır. Bu dönüşümde güvenilir bir partner olan sigorta şirketleri, dijital dünyada sıkıntıları tespit ederek çözümler sunmaktadır. Dijital sigortacılık, sigorta süreçlerini hızlandıran, müşterilere kolaylık sağlayan ve daha etkili hale getiren teknolojik çözümleri içermektedir. Bu süreçte güvenilir bir sigorta şirketi ya da aracılar; müşteri verilerinin güvenliğini sağlamak, hızlı ve kullanıcı dostu dijital platformlar sunmak, müşteri deneyimini geliştirmek ve çeşitli dijital kanallar üzerinden erişilebilir olmak gibi önemli faktörlere dikkat etmelidir.",
    },
    {
      img: "/img/blog-img-2.png",
      title: "Günümüzde Drone Kullanımının Artması",
      link: "blog/gunumuzde-drone-kullaniminin-artmasi-2",
      description:
        "Günümüzde, droneların kullanımı hızla artıyor ve çeşitli sektörlerde yaygın olarak kullanılmaya başlandı. Ancak, droneların uçuş sırasında çeşitli risklere maruz kalabileceği de bir gerçek. Bu nedenle, drone kullanıcıları, uçuş sırasında meydana gelebilecek kazalar ve hasarlar için özel sigorta poliçelerine ihtiyaç duyuyor.",
    },
  ],
  sectionTwo: [
    {
      img: "/img/blog-img-4.png",
      title: "Nakliyat Sırasında Yaşanan En Büyük Kayıplar ve Çözümleri",
      link: "blog/nakliyat-sirasinda-yasanan-en-buyuk-kayiplar-ve-cozumleri-4",
      description:
        "Nakliyat süreçleri tüm sektörlerde olduğu gibi çeşitli zorluklar ve sıkıntılar içerir. Doğru yaklaşımlar, doğru teminat ve bedellerle hazırlanmış bir nakliyat sigortası eşliğinde aşağıda sizlere sunduğumuz 5 madde çözüme kavuşacaktır. ",
    },
    {
      img: "/img/blog-img-3.png",
      title: "Hamilelikte Sağlıklı Beslenme İpuçları",
      link: "blog/hamilelikte-saglikli-beslenme-ipuclari-3",
      description:
        "Hamilelik döneminde, bebeğin sağlıklı gelişimi için anne adaylarının doğru ve dengeli beslenmesi çok önemlidir. Ancak, hamilelik sürecinde beslenme alışkanlıklarının değişmesi ve belirli yiyeceklerin tüketiminin sınırlanması gerektiği konusunda bazı endişeler de olabilir.",
    },
  ],
};

export const BlogCards = ({ data }) => {
  return (
    <>
      {Object.entries(data).map(([_, value], index) => (
        <React.Fragment key={index}>
          {index === 1 && <SocialMedia />}
          <Cards key={index} data={value} reverse={index % 2 !== 0} />
        </React.Fragment>
      ))}
    </>
  );
};

const Cards = ({ data, reverse }) => {
  return (
    <div
      className={classNames({
        "flex justify-between w-full py-11": true,
        "flex-col lg:flex-row-reverse": reverse,
        "flex-col lg:flex-row": !reverse,
      })}
    >
      {data &&
        data
          .sort((a, b) => a.OrderNumber - b.OrderNumber)
          .map((item, index) => {
            if (index % 2 === 0)
              return <CardOne key={index} item={item} reverse={reverse} />;
            else return <CardTwo key={index} item={item} reverse={reverse} />;
          })}
    </div>
  );
};

const CardOne = ({ item, reverse }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames({
        "flex flex-col flex-1 px-8 lg:px-16 py-9": true,
        "items-end ": !reverse,
        "items-start ": reverse,
      })}
    >
      <div className="w-full max-w-3xl">
        <div className="h-56 rounded-2xl ">
          <img
            alt="blog-img"
            src={`${IMG_URL_PORTAL + item.Content?.Media?.Link}`}
            className="w-full h-full rounded-2xl object-cover"
          />
        </div>
        <div className="flex flex-col gap-y-2">
          <h1 className="text-dark-cerulean font-light text-2xl mt-7 text-start">
            {item.Content?.Title}
          </h1>
          <p
            className="text-justify font-light"
            dangerouslySetInnerHTML={{
              __html: item.Content?.Details.substring(0, 500) + "...",
            }}
          ></p>
          <div className="w-full flex justify-end">
            <Link
              to={`/blog/${item.Content?.Id}`}
              className="underline font-light text-dark-cerulean"
            >
              {t("blogs.readMore")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const CardTwo = ({ item, reverse }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames({
        "bg-dark-cerulean-2 flex flex-1 px-8 lg:px-16 py-9 shadow-box-lg": true,
        "items-start justify-start rounded-none lg:rounded-l-3xl": !reverse,
        "items-end justify-end rounded-none lg:rounded-r-3xl": reverse,
      })}
    >
      <div className="max-w-md 2xl:max-w-xl w-full text-white flex justify-center items-center flex-col">
        <div className="h-56 w-full rounded-2xl">
          <img
            alt="blog-img"
            src={`${IMG_URL_PORTAL + item.Content?.Media?.Link}`}
            className="w-full h-full rounded-2xl object-cover"
          />
        </div>
        <div className="flex flex-col gap-y-4">
          <h1 className="font-light text-2xl mt-7 text-start w-64">
            {item.Content?.Title}
          </h1>
          <p
            className="text-justify font-light"
            dangerouslySetInnerHTML={{
              __html: item.Content?.Details.substring(0, 500) + "...",
            }}
          ></p>
          <div className="w-full flex justify-end">
            <Link
              to={`/blog/${item.Content?.Id}`}
              className="underline font-light text-white"
            >
              {t("blogs.readMore")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
