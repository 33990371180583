import { Breadcrumbs } from "components/Common/Breadcrumbs";
import { HeaderImage } from "components/Common/HeaderImage";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AccordionItem } from "./AccordionItem";

export const Service16 = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col justify-center items-center relative">
      <HeaderImage
        src="/img/service-16.jpg"
        title={t("services.healthWorker.title")}
        titleLeft={true}
        buttonTitle={t("services.healthWorker.buttonTitle")}
        buttonTo="/contact-us"
      />

      <div className="flex flex-col justify-center items-center">
        <Breadcrumbs
          title2={t("services.healthWorker.title")}
          title={t("services.breadCrumb")}
          href="/"
        />
      </div>
      {/*section 1*/}
      <div className="relative w-full flex justify-center h-full text-left lg:text-justify mt-16 px-9 lg:px-0">
        <div className="container max-w-3xl 2xl:max-w-4xl flex flex-col gap-y-4">
          <div
            className="flex flex-col gap-y-4 font-light"
            dangerouslySetInnerHTML={{
              __html: t("services.healthWorker.content.description.one"),
            }}
          ></div>
        </div>
      </div>

      {/*Section 2 */}
      <div className="w-full bg-whisper-2 mt-28 pb-7 flex items-center flex-col pt-11">
        {/* <Faq /> */}
      </div>
    </div>
  );
};

export const Faq = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="max-w-6xl 2xl:max-w-7xl flex flex-col gap-y-5 w-full min-h-full pb-40 px-4 lg:px-0">
      <h1 className="text-dark-cerulean text-2xl lg:text-4xl font-bold">
        Sıkça Sorulan Sorular
      </h1>
      <div className="flex flex-col gap-y-5">
        <AccordionItem
          title="Sağlığınız Bizde tamamlayıcı sağlık sigortasını yaptırmadan önceki tedavi masraflarımı da karşılar mı ?"
          id="panel-1"
          handleChange={handleChange}
          expanded={expanded}
        >
          <div className="flex flex-col font-light text-sm gap-y-3">
            <p>
              Evet. Sigorta başlangıç tarihinizden önce karşılaştığınız sağlık
              problemlerinizin tedavi masrafları kapsam dahilindedir.
            </p>
          </div>
        </AccordionItem>
        {/* <AccordionItem
          title="Sağlık sigortalarında uygulanan 55 yaş sınırı bu ürün için de geçerli midir ?"
          id="panel-2"
          handleChange={handleChange}
          expanded={expanded}
        >
          <div className="flex flex-col font-light text-sm gap-y-3">
            <p>
              Hayır. Sağlığınız bizde tamamlayıcı sağlık sigortası yaptırmak
              için üst yaş limiti 80 yaş olarak belirlenmiştir.
            </p>
          </div>
        </AccordionItem>
        <AccordionItem
          title="70 yaş üzerindeyim ya da kronik rahatsızlığı var. Bu durumda sigortaya nasıl dahil olabilirim ?"
          id="panel-3"
          handleChange={handleChange}
          expanded={expanded}
        >
          <div className="flex flex-col font-light text-sm gap-y-3">
            <p>
              70 yaş üzerindeki kişiler ile tedavi gerektiren önemli rahatsızlık
              veya kronik hastalığı bulunan bireylerin sigortadan yararlanması
              için aileden birinin daha sigorta yaptırması şartı aranır. 70 yaş
              üzeri sigortalı adayı ile tedavi gerektiren önemli bir
              rahatsızlığı bulunan sigortalı adayının tek başına sigortalanması
              halinde %100 sürprim uygulanacaktır.
            </p>
            <p>
              Bu bireylerin mevcut risk nedeniyle uygulanmış %100 sürprim,
              sigorta yenilemesinde tarife primi üzerinden hesaplanıp devam
              ettirilir. Yine aynı şekilde referans poliçe ile sigorta
              yaptırılmış olan sigortalıya referans poliçelerinin yenilemeleri
              yapılmaktadır.
            </p>
          </div>
        </AccordionItem> */}
      </div>
    </div>
  );
};
