import classNames from "classnames";
import IconSVG from "icons/IconSVG";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SlArrowLeft } from "react-icons/sl";
import { TfiAngleDown } from "react-icons/tfi";
import { Link } from "react-router-dom";

function HamburgerMenu() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isHomeOpen, setIsHomeOpen] = useState(false);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isSectorsOpen, setIsSectorsOpen] = useState(false);
  const [isInServicesOpen, setInIsServicesOpen] = useState(false);
  const [isiIndividualsOpen, setIsIndividualsOpen] = useState(false);
  const [isInstitutionalsOpen, setIInstitutionalsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [selected, setSelected] = useState(
    localStorage.getItem("language") || "tr"
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setSelected(localStorage.getItem("language"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleChangeLanguage = async (lng) => {
    await i18n.changeLanguage(lng);
    setSelected(lng);
  };

  const { i18n } = useTranslation();

  const toggleSubMenu = (submenu) => {
    switch (submenu) {
      case "home":
        setIsHomeOpen(!isHomeOpen);
        break;
      case "aboutUs":
        setIsAboutUsOpen(!isAboutUsOpen);
        break;
      case "services":
        setIsServicesOpen(!isServicesOpen);
        break;
      case "sectors":
        setIsSectorsOpen(!isSectorsOpen);
        setInIsServicesOpen(false);
        break;
      case "alt-services":
        setInIsServicesOpen(!isInServicesOpen);
        setIsSectorsOpen(false);
        break;
      case "individuals":
        setIsIndividualsOpen(!isiIndividualsOpen);
        setIInstitutionalsOpen(false);
        break;
      case "institutionals":
        setIInstitutionalsOpen(!isInstitutionalsOpen);
        setIsIndividualsOpen(false);
        break;
      default:
        break;
    }
  };

  const menuItemsData = [
    { title: t("header.sectors.health"), route: "/sector/saglik-sektoru" },
    {
      title: t("header.sectors.construction"),
      route: "/sector/insaat-sektoru",
    },
    {
      title: t("header.sectors.transportation"),
      route: "/sector/tasimacilik-sektoru",
    },
    {
      title: t("header.sectors.manufactoring"),
      route: "/sector/uretim-sektoru",
    },
    {
      title: t("header.sectors.automotive"),
      route: "/sector/otomotiv-sektoru",
    },
    {
      title: t("header.sectors.engineering"),
      route: "/sector/muhendislik-sektoru",
    },
    { title: t("header.sectors.education"), route: "/sector/egitim-sektoru" },
    { title: t("header.sectors.tourism"), route: "/sector/turizm-sektoru" },
    { title: t("header.sectors.sports"), route: "/sector/spor-sektoru" },
    {
      title: t("header.sectors.agricultureAndLivestockBreeding"),
      route: "/sector/tarim-ve-hayvan-sektoru",
    },
  ];

  const institutionalMenuItemsData = [
    {
      title: t("header.subServices.institutional.fire"),
      route: "/services/yangin-sigortasi",
    },
    {
      title: t("header.subServices.institutional.businessPackage"),
      route: "/services/is-yeri-paket-sigortalari",
    },
    {
      title: t("header.subServices.institutional.constructionAllRisk"),
      route: "/services/insaat-tum-riskler-sigortasi",
    },
    {
      title: t("header.subServices.institutional.transportation"),
      route: "/services/nakliyat-sigortasi",
    },
    {
      title: t("header.subServices.institutional.boatYacht"),
      route: "/services/tekne-ve-yat-sigortasi",
    },
    {
      title: t("header.subServices.institutional.employer"),
      route: "/services/isveren-sigortasi",
    },
    {
      title: t("header.subServices.institutional.personalAccident"),
      route: "/services/ferdi-kaza-sigortasi",
    },
    {
      title: t("header.subServices.institutional.dangerousGoods"),
      route: "/services/tehlikeli-madde-sigortasi",
    },
    {
      title: t("header.subServices.institutional.surety"),
      route: "/services/kefalet-sigortasi",
    },
    // {
    //   title: "Emniyetli Suistimal Sigortaları",
    //   route: "/services/emniyeti-suistimal-sigortalari",
    // },
  ];

  const individualMenuItemsData = [
    {
      title: t("header.subServices.individual.private"),
      route: "/services/ozel-saglik-sigortasi",
    },
    {
      title: t("header.subServices.individual.complementary"),
      route: "/services/tamamlayici-saglik-sigortasi",
    },
    {
      title: t("header.subServices.individual.tss"),
      route: "/services/sagliginiz-bizde-tss",
    },
    {
      title: t("header.subServices.individual.maternity"),
      route: "/services/dogum-sigortasi",
    },
    {
      title: t("header.subServices.individual.motorVehicle"),
      route: "/services/zorunlu-trafik-sigortasi",
    },
    {
      title: t("header.subServices.individual.comprehensive"),
      route: "/services/kasko-sigortasi",
    },
    {
      title: t("header.subServices.individual.earthquake"),
      route: "/services/dask-sigortasi",
    },
    {
      title: t("header.subServices.individual.home"),
      route: "/services/konut-sigortasi",
    },
    {
      title: t("header.subServices.individual.travel"),
      route: "/services/seyahat-sigortasi",
    },
  ];
  return (
    <div className="flex no-underline">
      <div
        className={classNames({
          "fixed top-0 left-0 w-full h-full z-50  bg-white border-r-8 text-dark-cerulean-2 border-dark-cerulean-2 transition-transform ease-in-out duration-300": true,
          "transform translate-x-0": isOpen,
          "-translate-x-full": !isOpen,
        })}
      >
        <div className="flex justify-between items-center py-6 px-8">
          <button
            className="flex flex-row w-auto"
            onClick={() => {
              handleChangeLanguage(selected === "tr" ? "en" : "tr");
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL + selected === "tr"
                  ? "/img/tr-flag.svg"
                  : "/img/eng-flag.svg"
              }
              className="w-6 h-6"
            />
            <h6 className="w-7 float-left font-light text-custom">
              {selected.toLocaleUpperCase()}
            </h6>
          </button>
          <button
            className="text-black hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-md p-2 inline-flex items-center justify-center"
            onClick={toggleMenu}
          >
            <SlArrowLeft size={25} className="text-dark-cerulean-2" />
          </button>
        </div>
        <div className="py-4 no-scrollbar  flex flex-col px-9 overflow-auto h-3/4 text-lg">
          <ul className="py-4 space-y-2">
            <li>
              <Link to="/" className="no-underline" onClick={toggleMenu}>
                {t("header.home")}
              </Link>
            </li>
            <li>
              <Link
                to="/about-us"
                className="no-underline"
                onClick={toggleMenu}
              >
                {t("header.aboutUs")}
              </Link>
            </li>
            <li>
              <button
                type="button"
                onClick={() => toggleSubMenu("aboutUs")}
                className="flex flex-row gap-x-5 items-center"
              >
                <span className="no-underline">{t("header.services")}</span>
                <TfiAngleDown
                  className={classNames({
                    "rotate-0": !isAboutUsOpen,
                    "rotate-180": isAboutUsOpen,
                  })}
                />
              </button>

              {isAboutUsOpen && (
                <div className="relative">
                  <div className="pl-5">
                    <button
                      className="no-underline flex items-center gap-x-3 w-32 justify-between"
                      onClick={() => toggleSubMenu("sectors")}
                    >
                      <span className="no-underline">{t("header.sector")}</span>
                      <TfiAngleDown
                        className={classNames({
                          "rotate-0": !isSectorsOpen,
                          "rotate-180": isSectorsOpen,
                        })}
                      />
                    </button>
                    {isSectorsOpen && (
                      <div className="relative pl-5">
                        <ul className="list-disc">
                          {menuItemsData.map((item, index) => (
                            <div key={index}>
                              <li>
                                <Link
                                  to={item.route}
                                  onClick={toggleMenu}
                                  className="hover:no-underline"
                                >
                                  {item.title}
                                </Link>
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="pl-5">
                    <button
                      className="no-underline flex items-center w-40 justify-between"
                      onClick={() => toggleSubMenu("alt-services")}
                    >
                      <span className="no-underline">
                        {t("header.services")}
                      </span>
                      <TfiAngleDown
                        className={classNames({
                          "rotate-0": !isInServicesOpen,
                          "rotate-180": isInServicesOpen,
                        })}
                      />
                    </button>
                    {isInServicesOpen && (
                      <div className="relative pl-5">
                        <div className="">
                          <button
                            className="no-underline flex items-center gap-x-3 w-32 justify-between"
                            onClick={() => toggleSubMenu("institutionals")}
                          >
                            <span className="no-underline">
                              {t("header.subServices.institutional.name")}
                            </span>
                            <TfiAngleDown
                              className={classNames({
                                "rotate-0": !isInstitutionalsOpen,
                                "rotate-180": isInstitutionalsOpen,
                              })}
                            />
                          </button>

                          {isInstitutionalsOpen && (
                            <div className="relative pl-5">
                              <ul className="list-disc">
                                {institutionalMenuItemsData.map(
                                  (item, index) => (
                                    <div key={index}>
                                      <li>
                                        <Link
                                          to={item.route}
                                          onClick={toggleMenu}
                                          className="hover:no-underline"
                                        >
                                          {item.title}
                                        </Link>
                                      </li>
                                    </div>
                                  )
                                )}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div className="">
                          <button
                            className="no-underline flex items-center gap-x-3 w-32 justify-between"
                            onClick={() => toggleSubMenu("individuals")}
                          >
                            <span className="no-underline">
                              {t("header.subServices.individual.name")}
                            </span>
                            <TfiAngleDown
                              className={classNames({
                                "rotate-0": !isiIndividualsOpen,
                                "rotate-180": isiIndividualsOpen,
                              })}
                            />
                          </button>

                          {isiIndividualsOpen && (
                            <div className="relative pl-5">
                              <ul className="list-disc">
                                {individualMenuItemsData.map((item, index) => (
                                  <div key={index}>
                                    <li>
                                      <Link
                                        to={item.route}
                                        onClick={toggleMenu}
                                        className="hover:no-underline"
                                      >
                                        {item.title}
                                      </Link>
                                    </li>
                                  </div>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <li>
                          <Link
                            to="/services/saglik-ve-calisan-yan-haklari"
                            className="no-underline"
                            onClick={toggleMenu}
                          >
                            {t("header.subServices.healthWorker")}
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/reinsurance"
                            className="no-underline"
                            onClick={toggleMenu}
                          >
                            {t("header.subServices.reasurance")}
                          </Link>
                        </li>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </li>
            <li>
              <Link to="/career" className="no-underline" onClick={toggleMenu}>
                {t("header.career")}
              </Link>
            </li>
            <li>
              <Link to="/contact" className="no-underline" onClick={toggleMenu}>
                {t("header.contact")}
              </Link>
            </li>
          </ul>
          <footer className="fixed bottom-0 flex gap-y-3 mb-3 flex-col">
            <p className="font-light">{t("header.hamburgerMenuSocial")}</p>
            <div className="flex flex-row items-center gap-x-4">
              <a
                href="https://www.facebook.com/profile.php?id=100079932017540"
                target="_blank"
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/icon-facebook.svg"}
                  className="w-6 h-6"
                  alt="facebook"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/imcsigortareasuransbrokerligi/"
                target="_blank"
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/icon-insta.svg"}
                  className="w-6 h-6"
                  alt="linkedin"
                />
              </a>
              <a href="https://www.instagram.com/imcbrokerlik/" target="_blank">
                <img
                  alt="instagram"
                  src={process.env.PUBLIC_URL + "/img/icon-instagram.svg"}
                  className="w-6 h-6"
                />
              </a>
            </div>
          </footer>
        </div>
      </div>
      <div className="flex justify-between items-center md:hidden">
        <button
          className="text-black hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-md p-2 inline-flex items-center justify-center"
          onClick={toggleMenu}
        >
          <IconSVG replace={false} src="/icons/menu-icon.svg" />
        </button>
      </div>
    </div>
  );
}

export default HamburgerMenu;
