import classNames from "classnames";

export const Input = ({ field, className, ...props }) => {
  return (
    <input
      className={classNames({
        "bg-white w-full rounded-2xl outline-none font-light text-xl px-5": true,
        [className]: className,
      })}
      {...props}
      {...field}
    />
  );
};

export default Input;
