import React, { useEffect } from "react";
import Avard from "../icons/smarti-post.png";
import { Modal } from "./Common/Modal";
import { useDispatch } from "react-redux";
import { AvardModalClose } from "slices/CommonSlice";
import { classNames } from 'classnames';

export const AwardModal = () => {
  let isOpen = true;
  const dispatch = useDispatch();

  function handleClose() {
    dispatch(AvardModalClose());
    isOpen = false;
  }
  useEffect(() => {
    if(isOpen == true){
        
        setTimeout(() => {
        handleClose();
        }, 5000);
    }
  });

  return (
    <Modal open={isOpen} handleClose={handleClose}>
    <div className="flex justify-center items-center">
      <img className="md:w-[600px] xl:w-200" src={Avard} />
    </div>
    </Modal>
  );
};
