import { ErrorMessage, Field } from "formik";
import { useState, useRef, useEffect } from "react";
import { ApprovalModal } from "../Components/ApprovalModal";
import { MobileApprovalModal } from "../Components/MobileApprovalModal";
import { useMediaQuery } from "@mui/material";

export const Checkbox = ({
  btnLabel,
  label,
  labelProps,
  name,
  setFieldValue,
  showModal,
  modal,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const sizeLg = useMediaQuery("(min-width:1024px)");

  const handleClose = () => setOpen(false);

  const onApproved = () => {
    setFieldValue(name, true);
    handleClose();
  };

  const valueChange = (event) => {
    const { checked } = event.target;
    if (checked && showModal) setOpen(true);
    else setFieldValue(name, checked);
  };

  return (
    <>
      <label className="flex gap-x-2 w-full text-white">
        <div>
          <Field name={name} component={Input} onChange={valueChange} />
        </div>
        <div className="flex flex-col">
          <div className="text-base font-light">
            <button
              type="button"
              className="underline text-sm md:text-base text-start"
              onClick={() => setOpen(true)}
            >
              {btnLabel}
            </button>
            <span
              className={
                labelProps && labelProps.className
                  ? labelProps.className
                  : "md:ml-1 text-sm md:text-base"
              }
            >
              {label}
            </span>
          </div>
          <ErrorMessage
            className="text-red-500 text-sm px-1"
            component="span"
            name={name}
          />
        </div>
      </label>
      {open == true && !sizeLg ? (
        <MobileApprovalModal
          onApproved={onApproved}
          modal={modal}
          open={open}
          handleClose={handleClose}
        />
      ) : (
        <ApprovalModal
          onApproved={onApproved}
          modal={modal}
          open={open}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

const Input = ({ field, meta, ...props }) => {
  const { form } = props;

  return (
    <input
      checked={form?.values[field?.name]}
      type="checkbox"
      className="w-5 h-5 text-purple-600 rounded focus:ring-purple-500 "
      {...field}
      {...props}
    />
  );
};
