import Axios from "axios";
import { localStorageGetItem } from "utils/LocalStorageUtils";
import { isEmpty } from "utils/StringUtils";

let instanceAxios;

export const AxiosInstance = () => {
  if (instanceAxios) {
    return instanceAxios;
  } else {
    instanceAxios = Axios.create();
    instanceAxios.defaults.headers.common["Accept-Language"] = "en";
    let token = localStorageGetItem("token");
    if (!isEmpty(token)) {
      instanceAxios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    }
    instanceAxios.interceptors.request.use(
      function (config) {
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    instanceAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    return instanceAxios;
  }
};
