import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer({ changeCookiePolicy }) {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full shadow-box-top-lg">
      <div className="flex flex-col bg-white items-center">
        <div className="flex flex-row w-full lg:h-40 max-w-7xl py-6 lg:py-0">
          <div className="w-full flex items-center justify-center lg:justify-start lg:pl-24">
            <Link
              to="/"
              className="flex justify-center lg:w-64 lg:h-20 w-56 h-[64px]"
            >
              <img
                src={process.env.PUBLIC_URL + "/img/imc_logo.png"}
                className="h-full w-full"
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  })
                }
              />
            </Link>
          </div>
          <div className="hidden lg:flex items-end justify-end w-full lg:pr-20">
            <div className="flex flex-row justify-start gap-x-5 h-full">
              <div className="flex flex-col h-16 px-2 group hover:h-40 transition-all w-32 text-white items-center gap-x-2 justify-center rounded-b-3xl bg-orange-peel">
                <img
                  src={process.env.PUBLIC_URL + "/img/tbs.svg"}
                  className="w-20 h-20 hidden group-hover:block "
                />
                <div className="flex flex-row items-center justify-center">
                  <img
                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                    className="w-6 h-6"
                  />
                  <a
                    className="text-center font-bold text-custom px-2 no-underline hover:text-white"
                    href="tel:0850 333 38 77"
                  >
                    0850 333 38 77
                  </a>
                </div>
              </div>
              <div className="flex flex-col h-16 px-2 group hover:h-40 transition-all w-32  text-white items-center gap-x-2 justify-center rounded-b-3xl bg-dark-cerulaen">
                <img
                  src={process.env.PUBLIC_URL + "/img/ımc.svg"}
                  className="w-24 h-24 hidden group-hover:block "
                />
                <div className="flex flex-row items-center justify-center">
                  <img
                    src={process.env.PUBLIC_URL + "/img/call.svg"}
                    className="w-6 h-6"
                  />
                  <a
                    className="text-center font-bold text-custom px-2 no-underline hover:text-white"
                    href="tel:0850 333 38 77"
                  >
                    0212 211 &nbsp;11 56
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-center w-full mt-10">
          <div className="flex flex-col lg:flex-row justify-between w-full max-w-7xl pb-3 text-center lg:text-start">
            <div className="flex flex-col gap-y-6 lg:gap-y-0 lg:flex-row items-center lg:items-end gap-x-5 w-full max-w-5xl justify-center text-center">
              <Link
                to="/data-process"
                className="text-custom font-light underline"
              >
                {t("footer.text.dataProcess.title")}
              </Link>
              <Link
                to="/internet-access-information"
                className="text-custom font-light underline"
              >
                {t("footer.text.webAccess.title")}
              </Link>
              <Link to="/KVKK" className="text-custom font-light underline">
                {t("footer.text.pdpl.title")}
              </Link>
              <Link
                to="/cookie-policy"
                className="text-custom font-light underline"
              >
                {t("footer.text.cookiePolicy.title")}
              </Link>
              <button
                type="button"
                onClick={changeCookiePolicy}
                className="text-custom font-light underline"
              >
                {t("footer.text.cookieSettings.title")}
              </button>
            </div>

            <div className="flex flex-row justify-center items-center mt-12 lg:mt-0 lg:items-end lg:justify-end gap-x-5 lg:mr-20">
              <a
                href="https://www.facebook.com/profile.php?id=100079932017540"
                target="_blank"
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/icon-facebook.svg"}
                  className="w-8 h-8"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/imcsigortareasuransbrokerligi/"
                target="_blank"
              >
                <img
                  src={process.env.PUBLIC_URL + "/img/icon-insta.svg"}
                  className="w-8 h-8"
                />
              </a>
              <a href="https://www.instagram.com/imcbrokerlik/" target="_blank">
                <img
                  src={process.env.PUBLIC_URL + "/img/icon-instagram.svg"}
                  className="w-8 h-8"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full border-t-2 border-black mt-7 lg:mt-0">
          <div className="flex text-center flex-col-reverse gap-y-2 space-y-4 lg:space-y-0 lg:gap-y-0 lg:flex-row items-center justify-between w-full max-w-7xl">
            <div className="flex flex-row items-center justify-center lg:justify-start w-full max-w-3xl lg:pl-24">
              <p className="font-light pb-4 lg:pb-0 text-custom">
                {t("footer.copyright")}
              </p>
            </div>

            <div className="flex flex-row items-center justify-center gap-x-2 lg:mr-20">
              <p className="text-custom font-light">powered by</p>
              <a href="https://www.affinitybox.com/" target="_blank">
                <img
                  className="w-[120px] -mt-1"
                  src={process.env.PUBLIC_URL + "/img/afb.svg"}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
