import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { HeaderImage } from "../Common/HeaderImage";
import { Breadcrumbs } from "../Common/Breadcrumbs";
import { getEntityUrl, resErrorMessage } from "utils/CommonUtils";
import { Requests } from "libs/Api";
import { IMG_URL, IMG_URL_PORTAL } from "libs/Constant";

export const Index = () => {
  const [blogData, setBlogData] = useState({});
  const params = useParams();

  useEffect(() => {
    try {
      let url = getEntityUrl({
        api: {
          baseName: "BaseApiName",
          url: `Contents/${params.id}`,
        },
      });
      Requests()
        .CommonRequest.get({
          url: url,
          sendLangType: false,
        })
        .then(({ data }) => {
          setBlogData(data);
        })
        .catch((error) => {
          let errorMessage = resErrorMessage(error);
          console.log(errorMessage);
        });
    } catch (error) {
      let errorMessage = resErrorMessage(error);
      console.log(errorMessage);
    }
  }, [params.id]);
  return (
    <div className="w-full h-full flex flex-col justify-center items-center pb-40 relative">
      <HeaderImage
        src={`${IMG_URL_PORTAL + blogData?.Value?.Media?.Link}`}
        title={blogData?.Value?.Title}
        titleLeft={true}
      />

      <div className="flex flex-col justify-center items-center">
        <Breadcrumbs
          title2={blogData?.Value?.Title}
          title="Blog"
          href="blogs"
        />
      </div>

      {/*Section 1 */}
      <div className="relative w-full flex justify-center h-full text-justify mt-16 px-9 lg:px-0">
        <div className="container max-w-3xl 2xl:max-w-4xl flex flex-col gap-y-16">
          <div
            dangerouslySetInnerHTML={{
              __html: blogData?.Value?.Details,
            }}
            className="font-light mt-3 flex flex-col gap-y-4 text-justify"
          >
            {/* <p>
              Gelişen teknoloji ve değişen müşteri beklentileriyle birlikte
              sigorta sektöründe önemli bir dönüşüm yaşamaktadır. Bu dönüşümde
              güvenilir bir partner olan sigorta şirketleri, sektörde
              sıkıntıları tespit ederek çözümler sunmaktadır.
            </p>

            <p>
              Dijital sigortacılık, sigorta süreçlerini hızlandıran, müşterilere
              kolaylık sağlayan ve daha etkili hale getiren teknolojik çözümleri
              içermektedir. Bu süreçte güvenilir bir sigorta şirketi ya da
              aracılar; müşteri verilerinin güvenliğini sağlamak, hızlı ve
              kullanıcı dostu dijital platformlar sunmak, müşteri deneyimini
              geliştirmek ve çeşitli dijital kanallar üzerinden erişilebilir
              olmak gibi önemli faktörlere dikkat etmelidir.
            </p> */}
          </div>

          {/* <img src="/img/blog-details-banner-img-1.png" />
          <div className="font-light mt-3 flex flex-col gap-y-4 text-justify">
            <p>
              Imc Sigorta ve Reasürans Brokerliği; müşteri verilerinin
              güvenliğini en üst düzeyde sağlamak adına sektörde yetkin
              firmalarla çalışmaktadır. Müşteri bilgileri çevrimiçi ortamlarda
              paylaşıldığı için güvenliğe büyük önem verilmelidir. Güçlü veri
              koruma protokolleri ve güvenlik önlemleri uygulamalı, müşteri
              verilerini yetkisiz erişimlere karşı korumalıdır. Hızlı ve
              kullanıcı dostu dijital platformlar sunmalıdır. Müşterilerin
              sigorta poliçelerini satın alması, güncellemesi veya hasar
              taleplerini bildirmesi gibi işlemleri hızlı ve kolay bir şekilde
              yapabilmeleri gerekmektedir. Kullanıcı dostu bir dijital platform,
              müşteri deneyimini olumlu yönde etkileyerek, müşteri memnuniyetini
              artırır.
            </p>

            <p>
              Ayrıca müşteri deneyimini geliştirmelidir. Müşteriye daha fazla
              kontrol ve kolaylık sağlama potansiyeli sunar. Dijitalleşen ve
              gelişen bu yeni platformda müşterilere çeşitli dijital kanallar
              aracılığıyla hizmet sunmalı, müşteriye kendine en uygun kanalı
              seçme imkânı sunmalıdır. Müşteri taleplerine hızlı ve etkili yanıt
              vermek, müşteri memnuniyetini artıracaktır. Dijital sigortacılıkta
              güvenilir bir partner olan Imc Sigorta ve Reasürans Brokerliği,
              sıkıntıları çözmek için çeşitli teknolojik çözümler sunmaya devam
              etmektedir.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};
