import { Breadcrumbs } from "components/Common/Breadcrumbs";
import { HeaderImage } from "components/Common/HeaderImage";
import { useState } from "react";
import { AccordionItem } from "../AccordionItem";

export const Service2 = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center relative">
      <HeaderImage
        src="/img/service-2.jpg"
        title="Emniyeti Suistimal Sigortaları"
        titleLeft={true}
        buttonTitle="Teklif Al"
        buttonTo="/get-offer"
        branchType="Emniyeti Suistimal Sigortaları"
        openButton={true}
      />

      <div className="flex flex-col justify-center items-center">
        <Breadcrumbs
          title2="Emniyeti Suistimal Sigortaları"
          title="Hizmetler"
          href="/"
        />
      </div>
      {/*section 1*/}
      <div className="relative w-full flex justify-center h-full text-left lg:text-justify mt-16 px-9 lg:px-0">
        <div className="container max-w-3xl 2xl:max-w-4xl flex flex-col gap-y-4">
          <p className="font-semibold text-san-marino"></p>
          <div className="flex flex-col gap-y-4 font-light">
            <p>
              Emniyeti Suistimal Sigortası, işletmelerin çalışanları tarafından
              işyerinde yapılan hırsızlık, dolandırıcılık, zimmete para geçirme
              gibi suistimal eylemlerine karşı koruma sağlayan bir sigorta
              türüdür. Bu tür sigortalar, işletmelerin finansal kayıplarını
              azaltmak için tasarlanmıştır.
            </p>
            <p>
              İşletmelerin çalışanlarının iş yerinde yaptığı hatalı veya haksız
              eylemler sonucu meydana gelebilecek maddi zararları karşılar.
              Örnek olarak, bir çalışanın şirket varlıklarını çalması, şirket
              hesaplarını manipüle etmesi, sahtekarlık veya dolandırıcılık
              yapması gibi durumlar emniyeti suistimal sigortası kapsamında
              değerlendirilebilir.
            </p>
            <p>
              Mali zararlara karşı korunmasını sağlar ve işletmelerin finansal
              güvenliğini artırır. Bu tür sigortalar genellikle işletmelerin
              ihtiyaçlarına göre özelleştirilebilir ve poliçeler, işletmenin
              sektörüne, büyüklüğüne, faaliyetlerine ve risk profiline göre
              uyarlanabilir.
            </p>
            <p>
              Güvenlik politikalarını, prosedürlerini ve kontrollerini
              geliştirmelerine de yardımcı olabilir. Sigorta şirketleri,
              işletmelerin riskleri yönetmelerine, güvenlik tedbirleri
              almalarına ve çalışanlarının suistimal eylemlerini önlemelerine
              yardımcı olmak için danışmanlık ve rehberlik de sağlayabilir.
            </p>
          </div>
        </div>
      </div>

      {/*Section 2 */}
      <div className="w-full bg-whisper-2 mt-28 pb-7 flex items-center flex-col pt-11">
        <Faq />
      </div>
    </div>
  );
};

export const Faq = () => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="max-w-5xl 2xl:max-w-6xl flex flex-col gap-y-5 w-full min-h-full pb-40 px-4 lg:px-0">
      <h1 className="text-dark-cerulean text-2xl lg:text-4xl font-bold">
        Sıkça Sorulan Sorular
      </h1>
      <div className="flex flex-col gap-y-5">
        <AccordionItem
          title="Emniyeti Suistimal sigortasında en yaygın riskler nelerdir ?"
          id="panel-1"
          handleChange={handleChange}
          expanded={expanded}
        >
          <div className="flex flex-col font-light text-sm gap-y-3">
            <p>
              İşletmelerin çalışanları tarafından işyerinde yapılan hatalı veya
              haksız eylemler sonucu meydana gelebilecek maddi zararlara karşı
              koruma sağlar. En yaygın riskler şunlar olabilir:
            </p>
            <p>
              <span className="font-bold text-black"> Hırsızlık: </span>
              Çalışanların şirket varlıklarını, ekipmanlarını, stoklarını veya
              diğer değerli malzemeleri çalması riski bulunmaktadır. Örneğin,
              bir çalışanın şirketin mülkiyetindeki ürünleri veya stokları
              izinsiz olarak çalması veya çalınmasına yardımcı olması gibi
              durumlar hırsızlık riskini oluşturabilir.
            </p>
            <p>
              <span className="font-bold text-black"> Dolandırıcılık: </span>
              Çalışanların sahte belgeler kullanarak şirketin finansal
              kaynaklarına erişmesi, şirket hesaplarını manipüle etmesi veya
              sahte faturalar, hileli masraflar veya diğer dolandırıcılık
              yöntemleri kullanarak şirketten haksız kazanç sağlaması riski
              bulunmaktadır.
            </p>
            <p>
              <span className="font-bold text-black">
                {" "}
                Zimmete Para Geçirme:{" "}
              </span>
              Çalışanların şirketin gelirini veya tahsilatını zimmetlerine
              geçirmesi, nakit veya diğer ödemeleri haksız kullanması veya
              yönlendirmesi riski bulunmaktadır.
            </p>
            <p>
              <span className="font-bold text-black"> İş kesintisi: </span>
              İşyerlerinde iş kesintisi riski bulunabilir. İş kesintisi, işin
              faaliyetlerini durdurma veya kısıtlama durumunda işletmelerin
              gelir kaybına yol açabilir.
            </p>
            <p>
              <span className="font-bold text-black"> İç İş birliği: </span>
              Birden fazla çalışanın veya çalışanların şirket içinde iş birliği
              yaparak haksız kazanç elde etme veya şirketin kaynaklarını hatalı
              veya haksız yollarla kullanma riski bulunmaktadır.
            </p>
            <p>
              <span className="font-bold text-black"> Yolsuzluk: </span>
              Şirketin satışları, satın almaları, sözleşmeleri veya diğer iş
              süreçlerini etkileyerek haksız kazanç sağlama, rüşvet, komisyon
              veya diğer yolsuzluk yöntemlerini kullanma riski bulunmaktadır.
              Bu, emniyeti suistimal sigortası kapsamında yer alan risklerden
              sadece birkaç örnektir. Her işletmenin risk profili farklı
              olabilir ve sigorta poliçesi, işletmenin ihtiyaçlarına göre
              özelleştirilebilir.
            </p>
          </div>
        </AccordionItem>
        <AccordionItem
          title="Emniyeti suistimal sigortasını kimler yaptırmalıdır ?"
          id="panel-2"
          handleChange={handleChange}
          expanded={expanded}
        >
          <div className="flex flex-col font-light text-sm gap-y-3">
            <p>
              İşverenlerin çalışanları tarafından işyerinde gerçekleştirilen
              hırsızlık, dolandırıcılık, sahtekârlık ve diğer yasa dışı
              faaliyetler nedeniyle meydana gelebilecek maddi zararları
              karşılayan bir sigorta türüdür. Hangi kişilerin bu sigortayı
              yaptırması gerektiği, genellikle işverenin faaliyet gösterdiği
              sektör, iş büyüklüğü, risk profili ve yerel yasal düzenlemelere
              bağlı olarak değişebilir.
            </p>
            <p>
              Emniyeti suistimal sigortasını yaptırmayı düşünebilecek bazı
              kişilere örnek olarak verilebilir:
            </p>
            <ul className="mx-3 list-decimal">
              <li>
                <span className="font-bold text-black"> İşverenler: </span>
                Herhangi bir sektörde faaliyet gösteren işverenler, çalışanları
                tarafından gerçekleştirilebilecek hırsızlık, dolandırıcılık,
                sahtekârlık gibi suistimal eylemlerine karşı maddi koruma
                sağlamak isteyebilir.
              </li>
              <li>
                <span className="font-bold text-black">
                  {" "}
                  Küçük işletme sahipleri:{" "}
                </span>
                Küçük işletme sahipleri, işyerlerinde sınırlı sayıda çalışana
                sahip olsalar bile, çalışanlarının potansiyel olarak suistimal
                eylemlerine karşı korunmak isteyebilir.
              </li>
              <li>
                <span className="font-bold text-black">
                  {" "}
                  Mali müşavirler ve hesap uzmanları:{" "}
                </span>
                Mali müşavirler, hesap uzmanları ve diğer mali danışmanlar,
                müşteri hesapları üzerinde potansiyel olarak
                gerçekleştirilebilecek suistimal eylemlerine karşı korunmak
                isteyebilir.
              </li>
              <li>
                <span className="font-bold text-black">
                  {" "}
                  Perakende işletmeleri:{" "}
                </span>
                Perakende işletmeleri, müşteri hizmetleri, kasiyer işlemleri ve
                stok yönetimi gibi süreçlerde gerçekleştirilebilecek hırsızlık
                ve sahtekârlık gibi suistimal eylemlerine karşı korunmak
                isteyebilir.
              </li>
              <li>
                <span className="font-bold text-black">
                  {" "}
                  Finansal kuruluşlar:{" "}
                </span>
                Bankalar, kredi kartı şirketleri, sigorta şirketleri gibi
                finansal kuruluşlar, çalışanları veya müşterileri tarafından
                potansiyel olarak gerçekleştirilebilecek suistimal eylemlerine
                karşı maddi koruma sağlamak isteyebilir.
              </li>
            </ul>
            <p>
              Ancak, her işletmenin ve faaliyetin kendine özgü bir risk profili
              bulunmaktadır. Emniyeti suistimal sigortası ihtiyacı, işletmenin
              faaliyet gösterdiği sektör, iş büyüklüğü, çalışan sayısı, mevcut
              güvenlik önlemleri ve diğer faktörlere göre değişebilir.{" "}
            </p>
          </div>
        </AccordionItem>
      </div>
    </div>
  );
};
