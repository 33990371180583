import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineArrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";

export default function PagesArea() {
  const { t } = useTranslation();
  return (
    <div className="w-full relative flex items-center justify-center px-9 py-10">
      <div className="max-w-5xl w-full grid grid-cols-2 lg:flex lg:flex-row gap-8 justify-items-center lg:justify-around lg:items-center">
        <Link to="/about-us" className="hover:no-underline">
          <div className="flex relative items-center justify-center group rounded-md w-[160px] h-[170px] lg:w-[185px] lg:h-[185px] text-white bg-cadet-blue hover:bg-paradiso text-[25px] font-light hover:text-[30px] transition-all duration-300">
            <span>{t("homePage.pagesArea.aboutUs")}</span>
            <div className="w-14 h-14 bg-white rounded-tl-3xl bottom-0 right-0 absolute group-hover:flex justify-center items-center hidden">
              <HiOutlineArrowRight className="text-dark-cerulean text-2xl" />
            </div>
          </div>
        </Link>
        <Link
          to="/reinsurance"
          className="hover:no-underline text-2xl font-light"
        >
          <div className="flex relative items-center justify-center group rounded-md w-[160px] h-[170px] lg:w-[185px] lg:h-[185px] text-white bg-golden-poppy hover:bg-dark-goldenrod text-[25px] font-light hover:text-[30px] transition-all duration-300">
            <span>{t("homePage.pagesArea.reinsurance")}</span>
            <div className="w-14 h-14 bg-white rounded-tl-3xl bottom-0 right-0 absolute group-hover:flex justify-center items-center hidden">
              <HiOutlineArrowRight className="text-dark-cerulean text-2xl" />
            </div>
          </div>
        </Link>
        <Link to="/blogs" className="hover:no-underline text-2xl font-light">
          <div className="flex relative items-center justify-center group rounded-md w-[160px] h-[170px] lg:w-[185px] lg:h-[185px] text-white bg-deep-lilac hover:bg-affair text-[25px] font-light hover:text-[30px] transition-all duration-300">
            <span>{t("homePage.pagesArea.blog")}</span>
            <div className="w-14 h-14 bg-white rounded-tl-3xl bottom-0 right-0 absolute group-hover:flex justify-center items-center hidden">
              <HiOutlineArrowRight className="text-dark-cerulean text-2xl" />
            </div>
          </div>
        </Link>
        <Link to="/contact" className="hover:no-underline text-2xl font-light">
          <div className="flex relative items-center justify-center group rounded-md w-[160px] h-[170px] lg:w-[185px] lg:h-[185px] text-white bg-chocolate hover:bg-rust text-[25px] font-light hover:text-[30px] transition-all duration-300">
            <span>{t("homePage.pagesArea.contact")}</span>
            <div className="w-14 h-14 bg-white rounded-tl-3xl bottom-0 right-0 absolute group-hover:flex justify-center items-center hidden">
              <HiOutlineArrowRight className="text-dark-cerulean text-2xl" />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
