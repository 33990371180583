import { Requests } from "libs/Api";
import { IMG_URL, IMG_URL_PORTAL } from "libs/Constant";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setLoading } from "slices/CommonSlice";
import { getEntityUrl, resErrorMessage } from "utils/CommonUtils";

function Carousel() {
  const { t } = useTranslation();
  const slides = [
    {
      url: "/img/blog-4.jpg",
      message: "Sigortacılıkta Güvenilir Partner",
    },
    {
      url: "/img/blog-2.jpg",
      message: "Sigortacılıkta Güvenilir Partner2",
    },
    {
      url: "/img/blog-3.jpg",
      message: "Sigortacılıkta Güvenilir Partner3",
    },

    {
      url: "/img/blog-1.jpg",
      message: "Sigortacılıkta Güvenilir Partner4",
    },
    {
      url: "/img/blog-2.jpg",
      message: "Sigortacılıkta Güvenilir Partner5",
    },
  ];

  const [blogData, setBlogData] = useState([]);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(setLoading(true));
  //   const timeout = setTimeout(() => {
  //     dispatch(setLoading(false));
  //   }, 1500);

  //   return () => clearTimeout(timeout);
  // }, []);

  useEffect(() => {
    dispatch(setLoading(true));
    try {
      let url = getEntityUrl({
        api: {
          baseName: "BaseApiName",
          url: `Blogs?Page=1&Size=6`,
        },
      });
      Requests()
        .CommonRequest.get({
          url: url,
        })
        .then(({ data }) => {
          setBlogData(data?.Value?.Data);
          dispatch(setLoading(false));
        })
        .catch((error) => {
          let errorMessage = resErrorMessage(error);
          console.log(errorMessage);
        });
    } catch (error) {
      let errorMessage = resErrorMessage(error);
      console.log(errorMessage);
    }
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide
      ? blogData.length != 0
        ? blogData.length - 1
        : slides.length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide =
      currentIndex ===
      (blogData.length != 0 ? blogData.length - 1 : slides.length - 1);
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="max-w-[full] h-80 md:h-96 lg:h-125 xl:h-80 2xl:h-150 w-full m-auto relative group bg-main-blue">
      <div
        style={
          blogData.length != 0
            ? {
                backgroundImage: `url(${
                  IMG_URL_PORTAL + blogData[currentIndex]?.Content?.Media?.Link
                })`,
              }
            : {
                backgroundImage: `url(${
                  IMG_URL_PORTAL + slides[currentIndex]?.Content?.Media?.Link
                })`,
              }
        }
        className="w-full h-full bg-center bg-cover duration-500 flex text-center items-end justify-center"
      >
        <div className="flex w-full lg:h-60 justify-center items-end bg-gradient-to-t pb-8 from-main-blue to-transparent">
          <div className="w-full h-full flex gap-y-9 lg:gap-y-0 flex-col lg:flex-row justify-between items-center lg:items-end max-w-6xl px-5">
            <div className="flex w-full items-center">
              <div className="flex items-end text-start text-white font-bold text-3xl lg:text-[45px] max-w-2xl border-l-4 px-4 leading-[50px]">
                {blogData[currentIndex]?.Content?.Title}
              </div>
            </div>

            <button
              type="button"
              className="border border-white text-white w-32 lg:w-[242px] h-[39px] backdrop-blur-xl text-[20px] font-light rounded-3xl max-md:text-sm"
            >
              <Link
                to={`/blog/${blogData[currentIndex]?.Content?.Id}`}
                className="hover:no-underline"
              >
                {t("homePage.carousel.button")}
              </Link>
            </button>
          </div>
        </div>
      </div>
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      <div className="flex top-4 justify-center py-2">
        {blogData.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className="text-2xl cursor-pointer"
          >
            <RxDotFilled color="#2A61A3" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Carousel;
