import { useTranslation } from "react-i18next";
import IconSVG from "../../icons/IconSVG";

export const Management = () => {
  const { t } = useTranslation();
  const data = [
    {
      FirstName: "Muhittin",
      LastName: "YURT",
      Title: t("aboutUs.sectionThree.management.title1"),
      Src: "/img/profile-pic.png",
      EmailAddress: "muhittin@imcbrokerlik.com",
    },
    {
      FirstName: "Cem Varol",
      LastName: "ACAR",
      Title: t("aboutUs.sectionThree.management.title2"),
      Src: "/img/profile-pic.png",
      EmailAddress: "varol@imcbrokerlik.com",
    },
    {
      FirstName: "Müge",
      LastName: "MUTLUDOĞAN ÇAKMAKÇI",
      Title: t("aboutUs.sectionThree.management.title3"),
      Src: "/img/profile-pic.png",
      EmailAddress: "muge@imcbrokerlik.com",
    },
    {
      FirstName: "Bora",
      LastName: "ŞENGÜNLER",
      Title: t("aboutUs.sectionThree.management.title4"),
      Src: "/img/profile-pic.png",
      EmailAddress: "bora@imcbrokerlik.com",
    },
    {
      FirstName: "İnci",
      LastName: "YURT",
      Title: t("aboutUs.sectionThree.management.title5"),
      Src: "/img/profile-pic.png",
      EmailAddress: "inci@imcbrokerlik.com",
    },
    {
      FirstName: "Ezgi Çağıl",
      LastName: "ŞARKİ",
      Title: t("aboutUs.sectionThree.management.title6"),
      Src: "/img/profile-pic.png",
      EmailAddress: "cagil@imcbrokerlik.com",
    },
  ];

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-7 lg:gap-32 mt-12">
      {data.map((item, index) => (
        <ManagementCard
          key={index}
          firstName={item.FirstName}
          lastName={item.LastName}
          title={item.Title}
          email={item.EmailAddress}
          src={item.Src}
        />
      ))}
    </div>
  );
};

const ManagementCard = ({ firstName, lastName, title, email, src }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-72.5 h-80 flex flex-col items-center border-b-2 border-dark-cerulean group">
        <div className="w-67.5 h-full relative">
          <img
            src={process.env.PUBLIC_URL + src}
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 left-0 h-0 group-hover:h-full group-hover:after:h-0 w-full overflow-hidden bg-management-gradient flex flex-col justify-end items-center group-hover:pb-6 transition-all duration-500">
            <div className="flex items-center justify-center gap-x-1 text-white text-2xl">
              <span className="font-light text-center">
                {firstName}{" "}
                <span className="font-bold text-center">{lastName}</span>
              </span>
            </div>
            <p className="font-light text-white text-sm text-center px-2">
              {title}
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center relative mt-4 group">
        <a
          href={`mailto:${email}`}
          className="bg-whisper rounded-2xl h-9 ml-4 justify-center items-center w-0 flex transition-all duration-300 group-hover:w-67.5 overflow-hidden"
        >
          {email}
        </a>
        <div className="absolute -top-2 left-0 bg-dark-cerulean w-12 h-12 rounded-full flex justify-center items-center ">
          <IconSVG
            replace={false}
            className="w-5 h-5"
            src={process.env.PUBLIC_URL + "/icons/mail-white-icon.svg"}
          />
        </div>
      </div>
    </div>
  );
};
