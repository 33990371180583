import classNames from "classnames";

export const TextArea = ({ field, className, ...props }) => {
  return (
    <textarea
      className={classNames({
        "resize-none bg-white w-full h-full rounded-2xl outline-none font-light p-5": true,
        [className]: className,
      })}
      rows={6}
      {...field}
      {...props}
    />
  );
};
