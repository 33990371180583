import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumbs } from "../Common/Breadcrumbs";
import { HeaderImage } from "../Common/HeaderImage";
import { AccordionItem } from "./AccordionItem";

export const Sector8 = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col justify-center items-center relative">
      <HeaderImage
        src="/img/sector-12.jpg"
        title={t("sectors.automotive.title")}
        titleLeft={true}
        buttonTitle={t("sectors.automotive.buttonTitle")}
        buttonTo="/sector-contact-us"
        openButton={true}
      />

      <div className="flex flex-col justify-center items-center">
        <Breadcrumbs
          title2={t("sectors.automotive.title")}
          title={t("sectors.breadCrumb")}
          href="/"
        />
      </div>
      {/*section 1*/}
      <div className="relative w-full flex justify-center h-full text-left lg:text-justify mt-16 px-9 lg:px-0">
        <div className="container max-w-3xl 2xl:max-w-4xl flex flex-col gap-y-4">
          <p className="font-semibold text-san-marino">
            {t("sectors.automotive.content.title")}
          </p>
          <div
            className="flex flex-col gap-y-4 font-light"
            dangerouslySetInnerHTML={{
              __html: t("sectors.automotive.content.description.one"),
            }}
          ></div>
        </div>
      </div>

      {/*Section 2 */}
      <div className="w-full bg-whisper-2 mt-28 pb-7 flex items-center flex-col pt-11">
        <Faq />
      </div>
    </div>
  );
};

export const Faq = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="max-w-5xl 2xl:max-w-6xl flex flex-col gap-y-5 w-full min-h-full pb-40 px-4 lg:px-0">
      <h1 className="text-dark-cerulean text-2xl lg:text-4xl font-bold">
        {t("sectors.automotive.faq.title")}
      </h1>
      <div className="flex flex-col gap-y-5">
        <AccordionItem
          title={t("sectors.automotive.faq.accordions.one.title")}
          id="panel-1"
          handleChange={handleChange}
          expanded={expanded}
        >
          <div className="flex flex-col font-light text-sm gap-y-3">
            <p>{t("sectors.automotive.faq.accordions.one.content")}</p>
          </div>
        </AccordionItem>
        <AccordionItem
          title={t("sectors.automotive.faq.accordions.two.title")}
          id="panel-2"
          handleChange={handleChange}
          expanded={expanded}
        >
          <div
            className="flex flex-col font-light text-sm gap-y-3"
            dangerouslySetInnerHTML={{
              __html: t("sectors.automotive.faq.accordions.one.content"),
            }}
          ></div>
        </AccordionItem>
      </div>
    </div>
  );
};
