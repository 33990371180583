import { useTranslation } from "react-i18next";
import IconSVG from "../../icons/IconSVG";

export const SocialMedia = () => {
  const { t } = useTranslation();
  return (
    <div className="py-8 flex flex-col gap-y-2 bg-white w-full justify-center items-center">
      <h4 className="text-dark-cerulean font-light">
        {t("blogs.socialMedia")}
      </h4>
      <div className="flex gap-x-5 justify-center">
        <a href="https://www.facebook.com/" target="_blank">
          <IconSVG
            replace={false}
            className="w-7 h-7"
            src={process.env.PUBLIC_URL + "/icons/facebook-icon.svg"}
          />
        </a>
        <a href="https://www.linkedin.com/" target="_blank">
          <IconSVG
            replace={false}
            className="w-7 h-7"
            src={process.env.PUBLIC_URL + "/icons/linkedin-icon.svg"}
          />
        </a>
        <a href="https://www.instagram.com/" target="_blank">
          <IconSVG
            replace={false}
            className="w-67 h-7"
            src={process.env.PUBLIC_URL + "/icons/instagram-icon.svg"}
          />
        </a>
      </div>
    </div>
  );
};
