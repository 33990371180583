import React from "react";
import { useTranslation } from "react-i18next";

export default function Social() {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col items-center gap-y-3 max-md:gap-y-4 justify-center px-9 py-8">
      <div className="lg:text-2xl font-light text-dark-cerulean-2 ">
        {t("homePage.socialMedia")}
      </div>
      <div className="flex flex-row items-center justify-center gap-x-6">
        <a
          href="https://www.facebook.com/profile.php?id=100079932017540"
          target="_blank"
          className="lg:w-9 lg:h-9 w-4 h-4"
        >
          <img
            src={process.env.PUBLIC_URL + "/img/icon-facebook.svg"}
            className="w-full h-full"
          />
        </a>
        <a
          className="lg:w-9 lg:h-9 w-4 h-4"
          href="https://www.linkedin.com/company/imcsigortareasuransbrokerligi/"
          target="_blank"
        >
          <img
            src={process.env.PUBLIC_URL + "/img/icon-insta.svg"}
            className="w-full h-full"
          />
        </a>
        <a
          href="https://www.instagram.com/imcbrokerlik/"
          target="_blank"
          className="lg:w-9 lg:h-9 w-4 h-4"
        >
          <img
            src={process.env.PUBLIC_URL + "/img/icon-instagram.svg"}
            className="w-full h-full"
          />
        </a>
      </div>
    </div>
  );
}
