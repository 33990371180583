import * as Yup from "yup";

export const CareerFormSchema = Yup.object().shape({
  FirstName: Yup.string().required("Ad alanı zorunludur."),
  LastName: Yup.string().required("Soyad alanı zorunludur."),
  MobileNumber: Yup.string()
    .matches(
      /^(?!0\s0)\d{1}\s\d{3}\s\d{3}\s\d{2}\s\d{2}$/,
      "Lütfen geçerli bir telefon numarası giriniz."
    )
    .required("Telefon numarası zorunludur."),
  EmailAddress: Yup.string()
    .email("Geçerli bir e-posta adresi giriniz.")
    .required("E-posta adresi zorunludur."),
  Content: Yup.string()
    .required("Mesaj zorunludur.")
    .max(140, "Lütfen en fazla 140 karakter giriniz."),
  Approval0: Yup.bool().oneOf([true], "Bu alanının onaylanması gerekmektedir."),
  Approval1: Yup.bool().oneOf([true], "Bu alanının onaylanması gerekmektedir."),
  Approval2: Yup.bool().oneOf([true], "Bu alanının onaylanması gerekmektedir."),
});
