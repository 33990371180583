import { decrypt, encrypt } from "./EncryptionUtils";

export const localStorageGetItem = (key) => {
  let storageKey = encrypt(`${process.env.PUBLIC_URL}_${key}`);
  let storageValue = localStorage.getItem(storageKey);
  let result = storageValue ? decrypt(storageValue) : null;
  return result;
};

export const localStorageSetItem = (key, value) => {
  let storageKey = encrypt(`${process.env.PUBLIC_URL}_${key}`);
  let storageValue = encrypt(value);

  localStorage.setItem(storageKey, storageValue);
};
export const localStorageClearKeys = (keys) => {
  return keys?.forEach((key) => {
    let storageKey = encrypt(`${process.env.PUBLIC_URL}_${key}`);
    localStorage.removeItem(storageKey);
  });
};
