import React from "react";

export const InfoCard = ({ data, title, titleColor, bgColor }) => {
  return (
    <div
      className={`flex flex-col justify-center gap-y-10 items-center text-center px-8 py-10 ${bgColor} lg:bg-white-smoke `}
    >
      <h1 className={`${titleColor} text-2xl font-bold pl-2 h-[10%]`}>
        {title}
      </h1>
      <p className="h-[90%]">{data}</p>
    </div>
  );
};
