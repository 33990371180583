import { useState } from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import VisibilitySensor from "react-visibility-sensor";

export default function DynamicCounter() {
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col items-center gap-y-8 max-md:gap-y-4 justify-center px-5 bg-counter-gradient py-11">
      <div className="max-w-6xl">
        <div className="flex lg:text-2xl text-white items-center font-semibold max-w-3xl">
          {t("homePage.dynamicCounter.description")}
        </div>
        <div className="mt-10 lg:mt-20">
          <VisibilitySensor
            partialVisibility
            offset={{ bottom: 200 }}
            onChange={(isVisible) => {
              if (isVisible) {
                setIsActive(true);
              }
            }}
          >
            <div className="grid grid-cols-3 font-bold gap-x-2 lg:gap-x-20">
              <div className="text-white flex flex-col gap-y-2 text-center lg:text-start">
                <h1 className="lg:text-5xl text-sm">
                  {isActive ? (
                    <>
                      <CountUp
                        decimals={1}
                        duration={3}
                        decimal="."
                        end={1.5}
                      />
                      {t("homePage.dynamicCounter.million")}
                    </>
                  ) : (
                    1
                  )}
                </h1>
                <h4 className="lg:text-3xl text-sm">
                  {t("homePage.dynamicCounter.titleOne")}
                </h4>
              </div>
              <div className="text-white flex flex-col text-center lg:text-start">
                <h1 className="lg:text-5xl text-sm">
                  {isActive ? <CountUp duration={3} end={65} /> : 1}
                </h1>
                <h4 className="lg:text-3xl text-sm">
                  {t("homePage.dynamicCounter.titleTwo")}
                </h4>
              </div>
              <div className="text-white flex flex-col text-center lg:text-start lg:pl-12">
                <h1 className="lg:text-5xl text-sm">
                  {isActive ? <CountUp duration={3} end={4} /> : 1}
                </h1>
                <h4 className="lg:text-3xl text-sm">
                  {t("homePage.dynamicCounter.titleThree")}
                </h4>
              </div>
            </div>
          </VisibilitySensor>
        </div>
      </div>
    </div>
  );
}
