import React, { useEffect, useState } from "react";
import { HeaderImage } from "../Common/HeaderImage";
import { Breadcrumbs } from "../Common/Breadcrumbs";
import { BlogCards } from "./BlogCards";
import { LatestArticle } from "./LatestArticle";
import { Requests } from "libs/Api";
import { getEntityUrl, resErrorMessage } from "utils/CommonUtils";
import { IMG_URL } from "libs/Constant";
import { useTranslation } from "react-i18next";

export const Index = () => {
  const { t } = useTranslation();
  const [blogData, setBlogData] = useState({ sectionOne: [], sectionTwo: [] });
  const [articleData, setArticleData] = useState([]);

  useEffect(() => {
    try {
      let url = getEntityUrl({
        api: {
          baseName: "BaseApiName",
          url: `Blogs?Page=1&Size=10`,
        },
      });
      Requests()
        .CommonRequest.get({
          url: url,
        })
        .then(({ data }) => {
          setBlogData({
            sectionOne: data?.Value?.Data.slice(0, 2),
            sectionTwo: data?.Value?.Data.slice(2, 4),
          });
          setArticleData(data?.Value?.Data);
        })
        .catch((error) => {
          let errorMessage = resErrorMessage(error);
          console.log(errorMessage);
        });
    } catch (error) {
      let errorMessage = resErrorMessage(error);
      console.log(errorMessage);
    }
  }, []);
  return (
    <div className="w-full h-full flex flex-col justify-center items-center pb-40 relative">
      <HeaderImage src="/img/blogs.png" title="BLOG" />

      <div className="flex flex-col justify-center items-center">
        <Breadcrumbs title="Blog" href="blogs" />
      </div>

      {/*Section 1 */}
      <div className="relative w-full flex justify-center h-full text-justify mt-16 px-9 lg:px-0">
        <div className="container max-w-3xl 2xl:max-w-4xl">
          <div className="font-light mt-3 flex flex-col gap-y-4 text-justify">
            <p>{t("blogs.content")}</p>
          </div>
        </div>
      </div>

      {/*Section 2 */}
      <div className="w-full bg-white-smoke mt-5 pb-7 flex items-center flex-col">
        <BlogCards data={blogData} />
      </div>

      {/*Section 3 */}
      <div className="relative w-full flex justify-center h-full text-justify mt-16 px-9 lg:px-0">
        <div className="container max-w-5xl 2xl:max-w-7xl">
          <h1 className="text-2xl font-bold text-dark-cerulean">
            {t("blogs.title")}
          </h1>
          <div className="pt-6">
            <LatestArticle data={articleData} />
          </div>
        </div>
      </div>
    </div>
  );
};
