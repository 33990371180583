import { useTranslation } from "react-i18next";
import IconSVG from "../../icons/IconSVG";
import { Breadcrumbs } from "../Common/Breadcrumbs";
import { HeaderImage } from "../Common/HeaderImage";

export const Index = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col justify-center pb-40 relative">
      <HeaderImage
        src="/img/reinsurance.png"
        title={t("services.reasurance.title")}
        buttonTitle={t("services.healthWorker.buttonTitle")}
        buttonTo="/contact-us"
      />

      <div className="flex flex-col justify-center items-center ">
        <Breadcrumbs title="Reasürans" href="reinsurance" />
        <div className="relative w-full flex justify-center h-full mb-14 px-10 text-left lg:text-justify lg:px-0">
          <div className="container max-w-3xl">
            {/*Section 1 */}
            <div className="mt-16 flex flex-col gap-y-3">
              <h1 className="text-2xl font-bold text-dark-cerulean">
                {t("services.reasurance.content.one.title")}
              </h1>
              <div
                className="font-light text-black flex flex-col gap-y-5"
                dangerouslySetInnerHTML={{
                  __html: t("services.reasurance.content.one.description"),
                }}
              ></div>
            </div>
            <div className="absolute -bottom-56 z-0 left-0 hidden xl:flex">
              <IconSVG
                replace={false}
                width={244}
                src={
                  process.env.PUBLIC_URL + "/icons/reinsurance-square-left.svg"
                }
              />
            </div>
          </div>
        </div>

        {/*Section 2 */}
        <div className="flex flex-col gap-y-3 w-full justify-center items-center z-10">
          <div className="container max-w-3xl px-10 lg:px-0">
            <h1 className="text-2xl font-bold text-dark-cerulean">
              {t("services.reasurance.content.two.title")}
            </h1>
          </div>
          <div className="flex justify-center bg-white-smoke w-full">
            <div
              className="container max-w-3xl py-9 flex flex-col gap-y-5 px-10 text-left lg:text-justify lg:px-0"
              dangerouslySetInnerHTML={{
                __html: t("services.reasurance.content.two.description"),
              }}
            ></div>
          </div>
        </div>
        <div className="relative w-full flex justify-center h-full mb-14 px-10 text-justify lg:px-0">
          <div className="container max-w-3xl">
            {/*Section 3 */}
            <div className="mt-16 flex flex-col gap-y-3">
              <h1 className="text-2xl font-bold text-dark-cerulean">
                {t("services.reasurance.content.three.title")}
              </h1>
              <div
                className="font-light text-black flex flex-col gap-y-1"
                dangerouslySetInnerHTML={{
                  __html: t("services.reasurance.content.three.description"),
                }}
              ></div>
            </div>
          </div>
          <div className="absolute -top-32  z-0 right-0 hidden xl:flex">
            <IconSVG
              replace={false}
              width={244}
              src={
                process.env.PUBLIC_URL + "/icons/reinsurance-square-right.svg"
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
