import { API_BASE_NAME, API_URL, APP_NAME } from "libs/Constant";
import { decrypt } from "./EncryptionUtils";
import { isEmpty } from "./StringUtils";

export const getEntityUrl = (asset) => {
  let apiBaseName = asset?.api?.baseName
    ? `/${API_BASE_NAME[asset?.api?.baseName]}`
    : "";
  return `${API_URL}${APP_NAME}/${asset?.api?.url}`;
};

export const isObjectIsNotEmpty = (value) =>
  typeof value === "object" && Object.keys(value).length > 0;

export const resErrorMessage = (error) => {
  error = {
    ...error,
    response: {
      ...error.response,
      data: error?.response?.data?.Response
        ? JSON.parse(decrypt(error?.response?.data?.Response))
        : error?.response?.data,
    },
  };
  let message;
  message =
    error.response?.data?.ErrorMessage &&
    Array.isArray(error.response?.data?.ErrorMessage) &&
    error.response?.data?.ErrorMessage?.length > 0
      ? error.response?.data?.ErrorMessage?.join(" ")
      : error.response?.data?.ErrorMessage
      ? error.response?.data?.ErrorMessage
      : error.response?.data?.ErrorMessageList &&
        error.response?.data?.ErrorMessageList?.length > 0
      ? error.response?.data?.ErrorMessageList?.join(" ")
      : !isEmpty(error.message) && typeof error.message === "string"
      ? error.message.includes("404")
        ? "Kayıt bulunamadı!"
        : error.message
      : "Beklenmeyen bir hata oluştu!";
  return message;
};

export const getCloudflareJSON = async () => {
  let data = await fetch("https://www.cloudflare.com/cdn-cgi/trace").then(
    (res) => res.text()
  );
  let arr = data
    .trim()
    .split("\n")
    .map((e) => e.split("="));
  return Object.fromEntries(arr);
};

export const getBrowserInfo = () => {
  var ua = navigator.userAgent;
  var tem;
  var M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE " + (tem[1] || "");
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(" ").replace("OPR", "Opera");
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M.join(" ");
};
