import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  localStorageGetItem,
  localStorageSetItem,
} from "utils/LocalStorageUtils";

function CookiePolicy({ cookieSettingsOpen, setCookieSettingsOpen }) {
  const { t } = useTranslation();
  const initialCookies = [
    {
      id: "sessionCookies",
      title: "cookiePolicy.sessionCookies.title",
      description: "cookiePolicy.sessionCookies.content",
      allowed: true,
      btnDisabled: true,
    },
    {
      id: "performanceCookies",
      title: "cookiePolicy.performanceCookies.title",
      description: "cookiePolicy.performanceCookies.content",
      allowed: false,
    },
    {
      id: "functionalCookies",
      title: "cookiePolicy.functionalCookies.title",
      description: "cookiePolicy.functionalCookies.content",
      allowed: false,
    },
    {
      id: "advertisingAndThirdPartyCookies",
      title: "cookiePolicy.advertisingAndThirdPartyCookies.title",
      description: "cookiePolicy.advertisingAndThirdPartyCookies.content",
      allowed: false,
    },
  ];

  const localStorageUserCookies = localStorageGetItem("userCookies");
  const [cookies, setCookies] = useState(
    localStorageUserCookies
      ? JSON.parse(localStorageUserCookies)
      : initialCookies
  );

  const [userSettings, setUserSettings] = useState(
    localStorageGetItem("userCookiesSettings")
  );

  const changeCookies = (currentCookie) => {
    if (currentCookie) {
      let changes = cookies.reduce((result, cookie) => {
        if (cookie.id === currentCookie.id)
          cookie.allowed = !currentCookie.allowed;

        return [...result, cookie];
      }, []);
      setCookies(changes);
    }
  };

  const allRejected = () => {
    localStorageSetItem("userCookiesSettings", "false");
    localStorageSetItem("userCookies", JSON.stringify(initialCookies));
    setCookies(initialCookies);
    setCookieSettingsOpen(false);
    setUserSettings(true);
  };

  const userAccept = () => {
    localStorageSetItem("userCookiesSettings", "true");
    localStorageSetItem("userCookies", JSON.stringify(cookies));
    setCookieSettingsOpen(false);
    setUserSettings(true);
  };

  return (
    <div>
      {userSettings !== null && !cookieSettingsOpen ? null : (
        <>
          {cookieSettingsOpen ? (
            <CookieSettings
              userAccept={userAccept}
              allRejected={allRejected}
              cookieSettingsOpen={cookieSettingsOpen}
              cookies={cookies}
              changeCookies={changeCookies}
            />
          ) : (
            <Policies
              openSettings={() => setCookieSettingsOpen(true)}
              userAccept={userAccept}
              allRejected={allRejected}
            />
          )}
        </>
      )}
    </div>
  );
}

const Policies = ({ openSettings, userAccept, allRejected }) => {
  const { t } = useTranslation();
  return (
    <div className="fixed bottom-0 left-0 bg-[#F8F8F8] w-full overflow-y-scroll pb-5 h-3/4 px-10 md:h-[342px] z-50 flex flex-col pt-[30px] md:pt-0 md:flex-row md:justify-center items-center font-poppins">
      <div className="text-black max-w-[275px] md:max-w-2xl lg:max-w-5xl text-base">
        <p>{t("cookiePolicy.title")}</p>
        <p
          className="mt-6"
          dangerouslySetInnerHTML={{
            __html: t("cookiePolicy.content"),
          }}
        ></p>
      </div>
      <div className="flex flex-col mt-8 md:mt-0">
        <button
          type="button"
          onClick={() => userAccept()}
          className=" h-[50px] lg:h-[60px] w-[275px] rounded-[10px] bg-[#004689] text-white"
        >
          {t("cookiePolicy.acceptButton")}
        </button>
        <button
          type="button"
          onClick={() => allRejected()}
          className="h-[50px] lg:h-[60px] w-[275px] rounded-[10px] border border-[#004689] text-[#004689] mt-3"
        >
          {t("cookiePolicy.rejectButton")}
        </button>
        <button
          onClick={openSettings}
          type="button"
          className="text-[#A9A9A9] font-bold mt-6 underline"
        >
          {t("cookiePolicy.settingsButton")}
        </button>
      </div>
    </div>
  );
};

const CookieSettings = ({
  cookieSettingsOpen,
  cookies,
  changeCookies,
  allRejected,
  userAccept,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames({
        "fixed bottom-0 left-0 bg-[#F8F8F8] w-full py-9 z-50 flex justify-center transition-all duration-500 font-poppins overflow-y-scroll": true,
        "h-3/4 lg:!h-[90%]": cookieSettingsOpen === true,
      })}
    >
      <div className="flex max-w-[275px] md:max-w-2xl lg:max-w-5xl flex-col w-full">
        <h1 className="font-bold text-xl lg:text-[35px] text-[#004689]">
          {t("cookiePolicy.settingsButton")}
        </h1>
        <div className=" mt-4 lg:mt-10 flex flex-col gap-y-3.5">
          {cookies.map((cookie, index) => (
            <div
              key={index}
              className="bg-white border-t-[15px] lg:border-t-0 lg:border-l-[20px] border-[#004689] min-h-[134px] p-4 flex flex-col"
            >
              <h5 className="font-bold">{t(cookie.title)}</h5>
              <div className="flex items-center mt-1 justify-between">
                <p className="text-xs lg:text-base max-w-4xl">
                  {t(cookie.description)}
                </p>
                <div className="">
                  <label className="relative inline-flex items-center cursor-pointer flex-col w-16 lg:w-36 gap-y-2.5">
                    <input
                      checked={cookie.allowed}
                      onChange={() => changeCookies(cookie)}
                      type="checkbox"
                      disabled={t(cookie.btnDisabled)}
                      className="sr-only peer"
                    />
                    <div className="w-10 h-5 lg:w-[60px] lg:h-[30px] rounded-[27px] bg-[#D9D9D9] after:content-[''] peer-checked:after:translate-x-full peer-checked:bg-[#004689] after:absolute after:bg-white after:border-[#939393] after:border after:rounded-full after:h-5 after:w-5  after:lg:h-[30px] after:lg:w-[30px] after:transition-all" />
                    {cookie.btnDisabled ? (
                      <span className="text-center">
                        {t("cookiePolicy.alwaysOn")}
                      </span>
                    ) : (
                      <span className="text-center">
                        {cookie.allowed
                          ? t("cookiePolicy.open")
                          : t("cookiePolicy.close")}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col-reverse gap-y-2.5 lg:flex-row gap-x-8 w-full justify-end mt-5 lg:mt-14 pb-10">
          <button
            type="button"
            onClick={allRejected}
            className=" h-10 lg:h-[60px] w-[275px] rounded-[10px] border border-[#004689] text-[#004689]"
          >
            {t("cookiePolicy.allRejectedButton")}
          </button>
          <button
            type="button"
            onClick={userAccept}
            className=" h-10 lg:h-[60px] w-[275px] rounded-[10px] bg-[#004689] text-white"
          >
            {t("cookiePolicy.allAcceptButton")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
