import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { localStorageSetItem } from "utils/LocalStorageUtils";
import { Modal } from "./Modal";
import { useState } from "react";

export const FormModal = ({ open, handleClose, title, buttonColor }) => {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Message
        title={title}
        handleClose={handleClose}
        buttonColor={buttonColor}
      />
    </Modal>
  );
};

const Message = ({ handleClose, title, buttonColor }) => {
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     handleClose();
  //   }, 5000);
  // });

  useEffect(() => {
    if (window.innerWidth < 640) {
      setIsSmallScreen(true);
    }
  }, []);

  return (
    <div
      className={`${isSmallScreen ? "h-[250px]" : "h-[250px]"} ${
        isSmallScreen ? "w-[45%] mt-60 ml-10" : "w-[45%] m-auto mt-72"
      } flex flex-col bg-white rounded-[3rem] items-center justify-end ${
        isSmallScreen ? "" : "px-3 max-xl:w-4/5"
      }`}
    >
      <div
        className={`flex flex-col w-full ${
          isSmallScreen ? "h-[75%]" : "h-[85%]"
        } justify-center items-center gap-y-10`}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: title,
          }}
          className="text-sm text-center"
        ></p>
        <button
          onClick={handleClose}
          className={`${buttonColor} rounded-xl text-white w-20 h-10`}
        >
          Tamam
        </button>
      </div>
    </div>
  );
};
