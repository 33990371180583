import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { PatternFormat } from "react-number-format";

export const PatternFormatInput = ({
  field,
  format,
  mask,
  className,
  props,
}) => {
  const { t } = useTranslation();
  return (
    <PatternFormat
      format={format}
      mask={mask}
      allowEmptyFormatting={false}
      placeholder={t("career.sectionForm.inputs.phoneNumber.placeHolder")}
      className={classNames({
        "bg-white w-full rounded-2xl outline-none font-light text-xl px-5": true,
        [className]: className,
      })}
      {...field}
      {...props}
    />
  );
};
