import React from "react";
import { Breadcrumbs as MUIBreadcrumbs } from "@mui/material";
import { BsChevronCompactRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import IconSVG from "../../icons/IconSVG";
import { useTranslation } from "react-i18next";

export const Breadcrumbs = ({ title, title2, href }) => {
  const { t } = useTranslation();
  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      className="mt-4 text-dark-cerulean font-normal text-custom font-poppins"
      classes={{ ol: "flex justify-center" }}
      separator={<BsChevronCompactRight fontSize="medium" />}
    >
      <Link
        underline="hover"
        color="inherit"
        to="/"
        className="flex gap-2 items-center"
      >
        <IconSVG
          replace={false}
          className="w-4 h-4"
          src={process.env.PUBLIC_URL + "/icons/home-icon.svg"}
        />
        {t("header.home")}
      </Link>
      <Link to={"/" + href} underline="hover" color="inherit">
        {title}
      </Link>
      {title2 && (
        <Link to={"#"} underline="hover" color="inherit">
          {title2}
        </Link>
      )}
    </MUIBreadcrumbs>
  );
};
