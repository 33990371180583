import { Requests } from "libs/Api";
import { IMG_URL, IMG_URL_PORTAL } from "libs/Constant";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getEntityUrl, resErrorMessage } from "utils/CommonUtils";

export default function Blogs() {
  const { t } = useTranslation();
  const [blogData, setBlogData] = useState([]);
  // DİNAMİK KISIM DAHA SONRA AKTİF EDİLECEKTİR.
  useEffect(() => {
    try {
      let url = getEntityUrl({
        api: {
          baseName: "BaseApiName",
          url: `Blogs?Page=1&Size=10`,
        },
      });
      Requests()
        .CommonRequest.get({
          url: url,
        })
        .then(({ data }) => {
          setBlogData(data?.Value?.Data);
        })
        .catch((error) => {
          let errorMessage = resErrorMessage(error);
          console.log(errorMessage);
        });
    } catch (error) {
      let errorMessage = resErrorMessage(error);
      console.log(errorMessage);
    }
  }, []);
  return (
    <div className="w-full flex items-center justify-center px-6 lg:px-10 py-3">
      <div className="flex flex-col lg:flex-row h-full gap-x-10 gap-y-12 max-w-[1700px]">
        {blogData.slice(1, 2).map((blog) => (
          <div className="flex flex-col gap-y-5 items-center h-full lg:max-w-2xl lg:px-2">
            <div className="w-full h-full">
              <img
                className="rounded-3xl w-full h-full"
                src={`${IMG_URL_PORTAL + blog.Content?.Media?.Link}`}
              />
            </div>
            <div className="text-main-blue lg:text-[25px] font-light flex flex-wrap w-full">
              {blog.Content?.Title}
            </div>
            <div
              className="font-light text-custom"
              dangerouslySetInnerHTML={{
                __html: blog.Content?.Details.substring(0, 250) + "...",
              }}
            ></div>
            <div className="w-full flex justify-end pr-5">
              <Link
                to={`/blog/${blog.Content?.Id}`}
                className="underline font-light text-custom text-main-blue"
              >
                {t("homePage.blogs")}
              </Link>
            </div>
          </div>
        ))}
        {blogData.slice(2, 3).map((blog) => (
          <div className="flex flex-col gap-y-6 lg:items-center justify-start w-full lg:max-w-sm">
            <div className="w-full h-full lg:max-h-64">
              <img
                className="rounded-3xl w-full h-full"
                src={`${IMG_URL_PORTAL + blog.Content?.Media?.Link}`}
              />
            </div>
            <div className="text-main-blue font-light lg:text-[25px]">
              {blog.Content?.Title}
            </div>
            <div
              className="font-light text-custom"
              dangerouslySetInnerHTML={{
                __html: blog.Content?.Details.substring(0, 250) + "...",
              }}
            ></div>
            <div className="w-full flex justify-end pr-5">
              <Link
                to={`/blog/${blog.Content?.Id}`}
                className="underline font-light text-main-blue"
              >
                {t("homePage.blogs")}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
