import React from "react";
import IconSVG from "../../icons/IconSVG";
import classNames from "classnames";

export const SectionMap = ({
  title,
  address1,
  address2,
  mail,
  phone,
  iframeSrc,
  reverse,
}) => {
  return (
    <>
      <div
        className={classNames({
          "w-full  flex justify-center items-center py-7 md:px-7": true,
          "bg-white-smoke": !reverse,
          "bg-white text-end": reverse,
        })}
      >
        <div className="container grid grid-cols-1 px-6 gap-y-8 lg:gap-y-0 md:px-0 lg:grid-cols-2 max-w-6xl">
          <div
            className={classNames({
              "w-full": true,
              "lg:order-last flex flex-col lg:items-end": reverse,
            })}
          >
            <h1 className="text-dark-cerulean font-bold text-2xl">{title}</h1>
            <p className="mt-6 font-normal">
              {address1} <br />
              {address2}
            </p>
            <div className="mt-9 gap-y-7 flex flex-col">
              <div
                className={classNames({
                  "flex gap-x-4 font-normal": true,
                  "lg:flex-row-reverse": reverse,
                })}
              >
                <IconSVG
                  replace={false}
                  className="w-6 h-6"
                  src={process.env.PUBLIC_URL + "/icons/mail-icon.svg"}
                />
                <a href={`mailto:${mail}`}>{mail}</a>
              </div>
              <div
                className={classNames({
                  "flex gap-x-4 font-normal": true,
                  "lg:flex-row-reverse": reverse,
                })}
              >
                <IconSVG
                  replace={false}
                  className="w-6 h-6"
                  src={process.env.PUBLIC_URL + "/icons/phone-icon.svg"}
                />
                <a href={`tel:${phone}`}>{phone}</a>
              </div>
            </div>
          </div>
          <div className="h-64 shadow-box-lg rounded-3xl max-w-2xl">
            <iframe
              title="map"
              width="100%"
              className="rounded-3xl"
              height="100%"
              src={iframeSrc}
            />
          </div>
        </div>
      </div>
    </>
  );
};
