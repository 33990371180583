import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Sectors() {
  const { t } = useTranslation();
  const sectors = [
    {
      title: t("homePage.sectors.health"),
      img: "bg-sector-one",
      route: "/sector/saglik-sektoru",
    },
    {
      title: t("homePage.sectors.construction"),
      img: "bg-sector-two",
      route: "/sector/insaat-sektoru",
    },
    {
      title: t("homePage.sectors.transportation"),
      img: "bg-sector-seven",
      route: "/sector/tasimacilik-sektoru",
    },
    {
      title: t("homePage.sectors.manufactoring"),
      img: "bg-sector-eleven",
      route: "/sector/uretim-sektoru",
    },
    {
      title: t("homePage.sectors.automotive"),
      img: "bg-sector-twelve",
      route: "/sector/otomotiv-sektoru",
    },
    {
      title: t("homePage.sectors.engineering"),
      img: "bg-sector-thirteen",
      route: "/sector/muhendislik-sektoru",
    },
    {
      title: t("homePage.sectors.education"),
      img: "bg-sector-three",
      route: "/sector/egitim-sektoru",
    },
    {
      title: t("homePage.sectors.tourism"),
      img: "bg-sector-five",
      route: "/sector/turizm-sektoru",
    },
    {
      title: t("homePage.sectors.sports"),
      img: "bg-sector-ten",
      route: "/sector/spor-sektoru",
    },
    {
      title: t("homePage.sectors.agricultureAndLivestockBreeding"),
      img: "bg-sector-eight",
      route: "/sector/tarim-ve-hayvan-sektoru",
    },
  ];

  return (
    <div className="w-full flex items-center justify-center pb-20 bg-sector-gray">
      <div className="flex flex-col h-full max-w-[1100px] w-full lg:px-10 xl:px-0 ">
        <div className="flex pl-7 lg:pl-0 flex-row items-center justify-start gap-x-2 lg:gap-x-4 w-full mt-10 text-main-blue font-extrabold lg:text-4xl">
          <img
            src={process.env.PUBLIC_URL + "/img/sector-icon.svg"}
            className="lg:w-20 lg:h-20 w-6 h-6"
          />
          <span className="font-bold text-2xl lg:text-[45px]">
            {t("homePage.sectors.title")}
          </span>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-5 gap-y-10 gap-x-5 items-center justify-items-center w-full mt-14 px-5 lg:px-0">
          {sectors.map((sector, index) => (
            <Link key={index} to={sector.route} className="hover:no-underline">
              <div className="flex items-center relative justify-center w-[170px] h-[136px] lg:w-[200px] lg:h-[166px] flex-shrink-0 text-white text-3xl group text-center overflow-hidden ">
                <div
                  className={`${sector.img} grayscale bg-no-repeat bg-cover bg-center absolute top-0 left-0 z-10 w-full h-full group-hover:scale-105 group-hover:grayscale-0 transition-all duration-300`}
                />
                <div className="absolute top-0 left-0 w-full h-full z-20 transition-all duration-500" />
                <span className="z-30 text-2xl font-bold">{sector.title}</span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
