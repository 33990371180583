import React from "react";
import Blogs from "./Blogs";
import StaticContent from "./StaticContent";
import Carousel from "./Carousel";
import Sectors from "./Sectors";
import Social from "./Social";
import DynamicCounter from "./DynamicCounter";
import PressAndNotice from "./PressAndNotice";
import PagesArea from "./PagesArea";
import ContactUs from "./ContactUs";

export const Index = () => {
  return (
    <div className="w-full h-full">
      <Carousel />
      <StaticContent />
      <Blogs />
      <Sectors />
      <Social />
      <DynamicCounter />
      <PressAndNotice />
      <ContactUs />
      <PagesArea />
    </div>
  );
};
