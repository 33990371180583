import React from "react";
import { Breadcrumbs } from "../Common/Breadcrumbs";
import { HeaderImage } from "../Common/HeaderImage";
import { SectionMap } from "./SectionMap";
import CareerForm from "./CareerForm";
import { useTranslation } from "react-i18next";

export const Index = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full h-full flex flex-col justify-center relative">
      <HeaderImage src="/img/career.jpg" title={t("career.title")} />

      <div className="flex flex-col justify-center items-center">
        <Breadcrumbs title={t("career.title")} href="contact" />
        {/*Section 1 */}
        <div className="relative w-full flex justify-center text-left lg:text-justify mt-16 px-9 lg:px-0">
          <div className="container max-w-3xl 2xl:max-w-4xl flex flex-col gap-y-4">
            <div
              className="flex flex-col gap-y-4 font-light"
              dangerouslySetInnerHTML={{
                __html: t("career.sectionOne.content"),
              }}
            ></div>
          </div>
        </div>
        <CareerForm></CareerForm>
      </div>
    </div>
  );
};
