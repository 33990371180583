import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

export const HeaderImage = ({
  src,
  title,
  titleLeft,
  buttonTitle,
  buttonTo,
  branchType,
  openButton,
}) => {
  return (
    <div className="w-full h-140 2xl:h-160 relative">
      <img
        className="w-full h-full object-cover"
        alt=""
        src={process.env.PUBLIC_URL + src}
      />
      <div className="absolute w-full h-44 bottom-0 left-0 bg-blue-gradient" />
      <div className="absolute bottom-0 left-0 flex lg:flex-row flex-col justify-start lg:justify-between w-full">
        <div
          className={classNames({
            "text-white bottom-0 left-0 flex w-full mb-3 ": true,
            "items-center justify-center flex-col gap-1 md:gap-4": !titleLeft,
            "items-center justify-end flex-row-reverse max-w-xl lg:mx-56 lg:py-14":
              titleLeft,
          })}
        >
          <h1 className="text-3xl md:text-[45px] leading-10 font-bold">
            {title}
          </h1>
          <div
            className={classNames({
              "text-white w-16 h-1 md:w-36 md:h-1 bg-white": true,
              "rotate-90": titleLeft,
            })}
          />
        </div>
        {openButton && (
          <div className="text-white self-center flex w-[231px] h-[60px] mb-3 lg:mr-56">
            <button
              type="button"
              className="border hover:bg-main-blue hover:text-white bg-white/30 border-white text-white w-[231px] h-[60px] text-[20px] font-bold rounded-xl max-md:text-sm bg-white backdrop-blur-sm"
            >
              <Link
                to={buttonTo}
                state={{ branchType: branchType }}
                className="hover:no-underline hover:text-white"
              >
                {buttonTitle}
              </Link>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
