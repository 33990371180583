import { Modal, Slide } from "@mui/material";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
export const ApprovalModal = ({ modal, open, handleClose, onApproved }) => {
  const modalRef = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (modalRef)
      modalRef.current?.scrollIntoView({ block: "center", behavior: "smooth" });
  }, [modalRef]);

  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        // sx={{
        //   top: -650,
        //   right: 550,
        // }}
      >
        <Slide direction="left" in={open}>
          <div
            className="modal-body scrollbar-hide overflow-hidden"
            ref={modalRef}
          >
            <div className="w-full flex justify-center items-center">
              <h4 className="text-[18px] font-bold text-center text-main-blue max-w-xl">
                {modal?.title}
              </h4>
            </div>
            <div className="my-5 text-sm 2xl:text-lg font-light scrollbar-hide overflow-auto h-[74%] lg:h-[78%] 2xl:h-[84%] relative">
              <p dangerouslySetInnerHTML={{ __html: modal?.content }} />
              <div className="bg-approvalmodal-content-border border-b-2 border-b-carrot-orange sticky bottom-0 left-0 w-full h-12" />
            </div>
            <div className="w-full flex justify-center items-start h-14">
              <button
                type="button"
                onClick={onApproved}
                className="h-9 font-light 2xl:text-xl text-white px-5 2xl:px-10 bg-main-blue rounded-2xl 2xl:rounded-3xl"
              >
                {t("career.sectionForm.approvalDetails.modalButton")}
              </button>
            </div>
          </div>
        </Slide>
      </Modal>
    </div>
  );
};
