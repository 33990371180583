import { IMG_URL, IMG_URL_PORTAL } from "libs/Constant";
import { Modal } from "../Common/Modal";

export const PressModal = ({ open, handleClose, title, details, imgUrl }) => {
  return (
    <Modal open={open} handleClose={handleClose}>
      <Message
        title={title}
        details={details}
        handleClose={handleClose}
        imgUrl={imgUrl}
      />
    </Modal>
  );
};

const Message = ({ title, details, handleClose, imgUrl }) => {
  return (
    <div className="xl:w-200 sm:w-[600px] md:w-[600px] ml-3 w-[350px] h-[420px] sm:h-[420px] md:h-[420px] lg:ml-3 md:ml-28 max-sm:ml-4 xl:h-140 flex flex-col">
      <div className="flex flex-row items-center justify-between gap-y-5">
        <div className="w-52 h-auto xl:w-auto xl:h-12 bg-main-blue text-white text-center pt-3 px-3 relative left-8 top-3">
          {title}
        </div>
        <div
          onClick={() => handleClose()}
          className="w-8 bg-main-blue h-8 text-center text-white font-extrabold pl-0 pt-[5px] relative top-9 xl:top-6 left-1 rounded-full cursor-pointer"
        >
          <span>x</span>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-y-3 py-2 pt-6 bg-white w-full h-full rounded-3xl">
        <div className="px-1 xl:px-0 xl:w-[750px] h-1/2 rounded-3xl">
          <img
            src={`${IMG_URL_PORTAL + imgUrl}`}
            alt="img-press"
            className="w-full h-full rounded-3xl"
          />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: details }}
          className="h-1/2 w-[340px] sm:w-[540px] xl:w-175 overflow-auto no-scrollbar"
        ></div>
      </div>
    </div>
  );
};
