import React from "react";
import { useTranslation } from "react-i18next";

export default function StaticContent() {
  const { t } = useTranslation();
  return (
    <div className="w-full flex items-center justify-center pt-10 max-md:pt-5 h-64 max-md:h-140 mb-24">
      <div className="flex flex-row max-md:flex-col max-md:items-center h-full max-w-7xl justify-center w-full gap-x-1">
        <div className="h-full w-full lg:w-[647px] text-dark-cerulean-2 font-bold flex text-center text-2xl xl:text-[40px] lg:leading-[50px] lg:pt-7 items-center justify-center lg:justify-start lg:pl-1">
          <p>
            {t("homePage.welcomeSection.title.partOne")}
            <br></br> {t("homePage.welcomeSection.title.partTwo")}
            <br></br>
            <p className="font-qwitcher font-bold text-5xl lg:text-[65px]">
              {t("homePage.welcomeSection.title.partThree")}
            </p>
          </p>
        </div>
        <div className="h-full w-full lg:w-1/2 max-md:w-full lg:pt-5 px-5 lg:px-0 lg:pr-0 justify-center text-black flex flex-col gap-y-1 lg:max-w-md">
          <p className="font-light text-justify text-custom">
            {t("homePage.welcomeSection.description.partOne")}
          </p>
          <p className="font-light text-left text-custom">
            {t("homePage.welcomeSection.description.partTwo")}
          </p>
          <p>{t("homePage.welcomeSection.description.partThree")}</p>
        </div>
      </div>
    </div>
  );
}
