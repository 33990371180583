import { ErrorMessage, Field } from "formik";

export const InputBase = ({
  label,
  name,
  type,
  style,
  inputClassName,
  component,
  format,
  mask,
  ...props
}) => {
  return (
    <div className="flex flex-col w-full h-full">
      <label htmlFor={name} className="pl-4 text-base font-light">
        {label}
      </label>
      <Field
        type={type}
        name={name}
        style={style}
        className={inputClassName}
        component={component}
        format={format}
        mask={mask}
        {...props}
      />
      <div>
        <ErrorMessage
          className="text-red-500 text-sm pl-4"
          component="span"
          name={name}
        />
      </div>
    </div>
  );
};
