import { Requests } from "libs/Api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getEntityUrl, resErrorMessage } from "utils/CommonUtils";
import { PressModal } from "./PressModal";

export default function PressAndNotice() {
  const [showModal, setshowModal] = useState({
    open: false,
    title: "",
    imgUrl: "",
    details: "",
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setshowModal({ open: false, title: "", details: "", imgUrl: "" });
  };
  const [announcementData, setAnnouncementData] = useState([]);
  const [pressReleasesData, setPressReleasesData] = useState([]);
  const { t } = useTranslation();

  const [selected, setSelected] = useState(
    localStorage.getItem("language") || "tr"
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setSelected(localStorage.getItem("language"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    try {
      let url = getEntityUrl({
        api: {
          baseName: "BaseApiName",
          url: `Announcements?Page=1&Size=10`,
        },
      });
      Requests()
        .CommonRequest.get({
          url: url,
        })
        .then(({ data }) => {
          setAnnouncementData(data?.Value?.Data);
        })
        .catch((error) => {
          let errorMessage = resErrorMessage(error);
          console.log(errorMessage);
        });
    } catch (error) {
      let errorMessage = resErrorMessage(error);
      console.log(errorMessage);
    }
  }, []);

  const monthNames = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];
  const monthNamesEng = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    try {
      let url = getEntityUrl({
        api: {
          baseName: "BaseApiName",
          url: `PressReleases?Page=1&Size=10`,
        },
      });
      Requests()
        .CommonRequest.get({
          url: url,
        })
        .then(({ data }) => {
          setPressReleasesData(data?.Value?.Data);
        })
        .catch((error) => {
          let errorMessage = resErrorMessage(error);
        });
    } catch (error) {
      let errorMessage = resErrorMessage(error);
    }
  }, []);

  return (
    <div className="w-full flex  h-full items-center justify-center bg-sector-gray py-14">
      <div className="flex flex-col lg:flex-row w-full h-full max-md:w-full gap-x-6 max-w-[1100px]">
        <div className="h-full w-full px-4 lg:px-0">
          <div className="w-full py-4 flex items-center justify-start text-dark-cerulean font-bold lg:text-[45px] text-2xl">
            {t("homePage.pressAndNotice.press")}
          </div>
          <div className="flex flex-col gap-y-10 w-full h-auto">
            {pressReleasesData
              .sort((a, b) => a.OrderNumber - b.OrderNumber)
              .map((press, index) => {
                const ContetDt = new Date(press?.CreatedDate);
                return (
                  <div
                    key={index}
                    onClick={() =>
                      setshowModal({
                        open: true,
                        title: press.Content?.Title,
                        details: press.Content?.Details,
                        imgUrl: press.Content?.Media?.Link,
                      })
                    }
                    className="flex flex-row w-full h-24 bg-white shadow-box-sm max-lg:text-sm gap-x-4 cursor-pointer relative group"
                  >
                    <div className="flex flex-col justify-center h-full w-44">
                      <div className="flex justify-end w-full text-[25px] font-bold text-black">
                        {ContetDt.getDate()}
                      </div>
                      <div className="flex justify-end w-full font-light text-custom">
                        {selected === "tr"
                          ? monthNames[ContetDt.getMonth()] +
                            " " +
                            ContetDt.getFullYear()
                          : monthNamesEng[ContetDt.getMonth()] +
                            " " +
                            ContetDt.getFullYear()}
                      </div>
                    </div>
                    <div className="flex items-center justify-start w-full h-full text-custom font-bold text-dark-cerulean px-10 ">
                      {press.Content.Title}
                    </div>
                    <div className="absolute w-full h-1.5 bottom-0 left-0 bg-dark-cerulean-2 hidden group-hover:flex" />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="h-full lg:w-64 px-4 lg:px-0">
          <div className="w-full py-4 text-center flex items-center lg:justify-center text-dark-cerulean font-bold lg:text-[45px] text-2xl">
            {t("homePage.pressAndNotice.notice")}
          </div>
          <div className="flex flex-col items-center justify-center gap-y-10 w-full cursor-pointer">
            {announcementData &&
              announcementData
                .sort((a, b) => a.OrderNumber - b.OrderNumber)
                .map((announcement, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      setshowModal({
                        open: true,
                        title: announcement.Content?.Title,
                        details: announcement.Content?.Details,
                        imgUrl: announcement.Content?.Media?.Link,
                      })
                    }
                    className="flex items-center justify-start lg:justify-between group w-full h-24 bg-white shadow-box-lg max-lg:text-sm text-main-blue text-start"
                  >
                    <div className="h-3/4 w-2 bg-main-blue opacity-0 group-hover:opacity-100" />
                    <span className="px-3.5 hover:font-bold text-custom h-full w-[90%] flex items-center">
                      {announcement?.Content?.Title}
                    </span>
                  </div>
                ))}
          </div>
        </div>
      </div>
      {showModal.open && (
        <PressModal
          open={showModal.open}
          title={showModal.title}
          details={showModal.details}
          imgUrl={showModal.imgUrl}
          handleClose={handleClose}
          // handleClose={() => {
          //   setshowModal({ open: false, title: "", details: "", imgUrl: "" });
          // }}
        />
      )}
    </div>
  );
}
