import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  loading: false,
  avardModalIsShowed: true,
  toastr: {
    open: false,
    message: "",
    severity: "success", //error || success || warning || info
    vertical: "top",
    horizontal: "center",
  },
};

export const commonSlice = createSlice({
  name: "common",
  initialState: initialState,
  reducers: {
    AvardModalClose(state,action) {
      state.avardModalIsShowed = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setToastr: (state, action) => {
      state.toastr = { ...state.toastr, ...action.payload };
    },
    toastrClose: (state) => {
      state.toastr = { ...state.toastr, open: false, message: "" };
    },
  },
});

export const { setLoading, setToastr, toastrClose, AvardModalClose } = commonSlice.actions;

export default commonSlice.reducer;
