import {
  decrypt,
  encrypt,
  combineAndEncryptParameters,
} from "utils/EncryptionUtils";
import { AxiosInstance } from "./AxiosInstance";
import { isEmpty } from "utils/StringUtils";

export const Requests = () => {
  const axiosInstance = AxiosInstance();
  let language = localStorage.getItem("language");
  return {
    CommonRequest: {
      async get(data) {
        let languageUrl = `${data?.url}&LangType=${
          language == "tr" ? "0" : "1"
        }`;
        let url = combineAndEncryptParameters(
          data.sendLangType == false ? data.url : languageUrl,
          data.content
        );
        let response = await axiosInstance.get(url, { headers: data.headers });
        if (response && response?.data && response.data?.response) {
          response = {
            ...response,
            data: JSON.parse(decrypt(response?.data?.response)),
          };
        }
        return response;
      },
      async post(data) {
        let content;
        if (!isEmpty(data.content))
          content = { Request: encrypt(JSON.stringify(data.content)) };
        let response = await axiosInstance.post(data.url, content, {
          headers: data.headers,
        });
        if (response && response?.data && response.data?.response) {
          response = {
            ...response,
            data: JSON.parse(decrypt(response?.data?.response)),
          };
        }
        return response;
      },
      async put(data) {
        let content = { Request: encrypt(JSON.stringify(data.content)) };
        let response = await axiosInstance.put(data.url, content, {
          headers: data.headers,
        });
        if (response && response?.data && response.data?.response) {
          response = {
            ...response,
            data: JSON.parse(decrypt(response?.data?.response)),
          };
        }
        return response;
      },
      async delete(data) {
        let response = await axiosInstance.delete(
          `${data.url}/${encrypt(JSON.stringify(data.content.id))}`,
          { headers: data.headers }
        );
        if (response && response?.data && response.data?.response) {
          response = {
            ...response,
            data: JSON.parse(decrypt(response?.data?.response)),
          };
        }
        return response;
      },
      async getById(data) {
        let response = await axiosInstance.get(
          `${data.url}/${encrypt(JSON.stringify(data.content.id))}`,
          { headers: data.headers }
        );
        if (response && response?.data && response.data?.response) {
          response = {
            ...response,
            data: JSON.parse(decrypt(response?.data?.response)),
          };
        }
        return response;
      },
    },
  };
};
